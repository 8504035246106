import classNames from "classnames/bind";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSUploaderWrapper } from "../../../components/SoSUploaderWrapper/SoSUploaderWrapper";
import styles from "./TestUploader.module.scss";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import { useMDatabase_UploadImages } from "../../../hooks/database/mutations/useMDatabase_UploadImages";
import { useMDatabase_UploadFiles } from "../../../hooks/database/mutations/useMDatabase_UploadFiles";

const cx = classNames.bind(styles);

export const TestUploader = () => {
   const db_P = dbcb("sos_properties", [{ column: "pid", id: "ChIJ1TYiDHPQNIgRTDK3xPmzJfw" }]);
   // const mUploadImages = useMDatabase_UploadImages(db_P("scope_of_work"));
   const mUploadFiles = useMDatabase_UploadFiles(db_P("scope_of_work"));

   const handleUploadImages = (files: FileList) => {
      // mUploadImages.mutate({ images: files });
      mUploadFiles.mutate({ files });
   };

   return (
      <div className={cx("container")}>
         <SoSUploaderWrapper type="files" onUpload={handleUploadImages}>
            <SHrSosButton type="text" buttonProps={{ className: cx("upload-btn") }}>
               Upload
            </SHrSosButton>
         </SoSUploaderWrapper>
      </div>
   );
};
