import classNames from "classnames/bind";
import styles from "./OffersInspection.module.scss";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SoSDataPoint } from "../../../../components/SoSDataPoint/SoSDataPoint";
import { useQOffers_GetLeadAirTable } from "../../../../hooks/offers/queries/airtable/useQOffers_GetLeadAirTable";
import { formatDate } from "../OffersOffersSection/OffersCard/OffersCard";

const cx = classNames.bind(styles);

export const OffersInspection = () => {
   const qGetLeadAirTable = useQOffers_GetLeadAirTable();

   const { inspections } = qGetLeadAirTable.data || {};

   return (
      <div className={cx("container")}>
         <div className={cx("wrapper")}>
            <div className={cx("addInspectionC")}>
               <span>Inspection</span>
               {/* <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                  </div>
                  Schedule an inspection
               </SHrSosButton> */}
            </div>
            {qGetLeadAirTable.isLoading ? (
               <div className={cx("spinnerC")}>
                  <SHrSpinner />
               </div>
            ) : inspections?.length ? (
               inspections.map((inspection, index) => (
                  <div className={cx("inspectionC")} key={index}>
                     <span>
                        <b>Inspection {index + 1}</b>
                     </span>
                     <div className={cx("icon-link")}>
                        <a
                           title="View in Airtable"
                           href={`https://airtable.com/apptx6ZpUz4a3xAwX/tblG8QMxQsYOabKyz/${inspection.record_id}`}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                           <SoSDataPoint
                              type="bold-title"
                              title="Property Address"
                              value={inspection?.street_address}
                              icon="airtable"
                           />
                        </a>
                     </div>

                     <div className={cx("inspection")}>
                        <SoSDataPoint type="bold-title" title="Inspecion Status" value={inspection.inspection_status} />
                        <SoSDataPoint
                           type="bold-title"
                           title="Estimated Total Cost"
                           value={inspection.estimated_total_cost}
                           {...(inspection.estimated_total_cost && { format: "currency" })}
                        />
                        <SoSDataPoint type="bold-title" title="Inspector" value={inspection.inspector?.name} />
                     </div>

                     <div className={cx("inspection")}>
                        <SoSDataPoint
                           type="bold-title"
                           title="Date Created"
                           value={formatDate(inspection.date_created)}
                        />
                        <SoSDataPoint
                           type="bold-title"
                           title="Date Walkthrough Performed"
                           value={formatDate(inspection.date_walkthrough_performed || "")}
                        />
                        <SoSDataPoint
                           type="bold-title"
                           title="Date Inspection Submitted"
                           value={formatDate(inspection.date_inspection_submitted || "")}
                        />
                     </div>

                     <div className={cx("inspection")}>
                        <div className={cx("otherReasonC")}>
                           <span>
                              <b>Access Details</b>
                           </span>
                           <textarea placeholder="Note to the assignee..." value={inspection.access_details} readOnly />
                        </div>

                        <div className={cx("otherReasonC")}>
                           <span>
                              <b>Notes</b>
                           </span>
                           <textarea
                              placeholder="Note to the assignee..."
                              value={inspection.inspection_notes}
                              readOnly
                           />
                        </div>
                     </div>
                  </div>
               ))
            ) : (
               <div className={cx("noInspectionC")}>
                  <span>No inspection recorded yet.</span>
               </div>
            )}
         </div>

         {/* <div className={cx("leadDocSC")}>
            <div className={cx("docsC")}>
               <div className={cx("docC")}>
                  <span>SOW</span>
                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>
                  <div className={cx("filesC")}>
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                     <Documentation />
                  </div>
               </div>
               <div className={cx("docC")}>
                  <span>Disclosure Documents</span>
                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>
               </div>
            </div>

            <div className={cx("docsC")}>
               <div className={cx("docC")}>
                  <span>Lease Documents</span>
                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>
               </div>
               <div className={cx("docC")}>
                  <span>Other Documents</span>
                  <SHrSosButton type="tonal" buttonProps={{ className: cx("uploadBtn") }}>
                     Upload a file
                  </SHrSosButton>
               </div>
            </div>
         </div> */}
      </div>
   );
};

// const Documentation = () => {
//    return (
//       <div className={cx("linkUploadC")}>
//          <div className={cx("img-icon")}>
//             <img src={CF_CDN_URL("/assets/property_validator/picture_as_pdf_black.svg")} alt="" />
//          </div>
//          <span>File name.pdf</span>
//          <div className={cx("linkIconsC")}>
//             <div className={cx("img-icon")}>
//                <img src={CF_CDN_URL("/assets/property_validator/drive_file_rename_outline_black.svg")} alt="" />
//             </div>
//             <div className={cx("img-icon")}>
//                <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
//             </div>
//             <div className={cx("img-icon")}>
//                <img src={CF_CDN_URL("/assets/property_validator/delete_black.svg")} alt="" />
//             </div>
//          </div>
//       </div>
//    );
// };
