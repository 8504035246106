import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";

import styles from "./SignInPage.module.scss";
import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { useSosAuth } from "../../hooks/auth/useSosAuth";

export const SignInPage = () => {
   const { logIn } = useSosAuth();
   useGoogleOneTapLogin({ onSuccess: logIn, onError: () => console.log("errored") });

   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.imgC}`}>
            <div></div>
            <img src={CF_CDN_URL("/assets/sign_in_background.png")} alt="" />
         </div>
         <div className={`${styles.loginC}`}>
            <header className={`${styles.top}`}>
               <img src={CF_CDN_URL("/assets/logo_dark.svg")} alt="" />
               <span>Simply OS</span>
            </header>
            <div className={`${styles.center}`}>
               <h1>Welcome to Simply OS!</h1>
               <p>Please use your simplyhomes.com email address that is already registered on this platform.</p>
               <GoogleLogin onSuccess={logIn} onError={() => console.log("erroed")} />
            </div>
            <div></div>
         </div>
      </section>
   );
};