import classNames from "classnames/bind";
import styles from "./TransactionAddNewInsurancePolicyModal.module.scss";
import { useClickOutside } from "../../../hooks/react/useClickOutside";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import { useState } from "react";
import {
   Transactions_CreateInsurancePolicy_Body_Params,
   useMTrans_CreateInsurancePolicy,
} from "../../../hooks/transactions/mutates/insurancePolicy/useMTrans_CreateInsurancePolicy";

const cx = classNames.bind(styles);

type TransactionAddNewInsurancePolicyModalProps = {
   onClose: () => void;
};

export const TransactionAddNewInsurancePolicyModal = ({ onClose }: TransactionAddNewInsurancePolicyModalProps) => {
   const { ref } = useClickOutside({ onClickOutside: onClose });

   const { mutate: muateCreateInsurancePolicy, isPending: isPendingCreateInsurancePolicy } =
      useMTrans_CreateInsurancePolicy();

   const [createBodyParams, setCreateBodyParams] = useState<Transactions_CreateInsurancePolicy_Body_Params>({
      number: "",
      effective_date: "",
      documents: [],
      cost: 0,
   });

   const handleCreateNewPolicy = () => {
      muateCreateInsurancePolicy(createBodyParams);
      onClose();
   };

   return (
      <div className={cx("container")} ref={ref}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/policy_black.svg")} alt="" />
            </div>
            <span>Add New Policy</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("bodyC")}>
            {/* <div className={cx("input_search")}>
               <SoSInputTitle
                  value={createBodyParams.title}
                  title="Title"
                  placeholder="Enter title"
                  type="text"
                  handleValue={(value) =>
                     setCreateBodyParams({
                        ...createBodyParams,
                        title: value,
                     })
                  }
               />
            </div> */}
            <div className={cx("input_search")}>
               <SoSInputTitle
                  value={createBodyParams.number || ""}
                  title="Number"
                  placeholder="Enter number"
                  type="text"
                  handleValue={(value) =>
                     setCreateBodyParams({
                        ...createBodyParams,
                        number: value,
                     })
                  }
               />
            </div>
            <div className={cx("input_search")}>
               <SoSInputTitle
                  value={createBodyParams.effective_date || ""}
                  title="Effective Date"
                  type="date"
                  handleValue={(value) =>
                     setCreateBodyParams({
                        ...createBodyParams,
                        effective_date: value,
                     })
                  }
               />
            </div>
            <div className={cx("input_search")}>
               <SoSInputTitle
                  value={createBodyParams.cost || ""}
                  title="Monthly Cost"
                  placeholder="Enter cost"
                  type="number"
                  handleValue={(value) =>
                     setCreateBodyParams({
                        ...createBodyParams,
                        cost: Number(value),
                     })
                  }
               />
            </div>
         </div>
         <SoSConfirmWrapper type="default" onConfirm={handleCreateNewPolicy}>
            <div className={cx("btnSave")}>
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                  }}
                  loading={isPendingCreateInsurancePolicy}
               >
                  Add Policy
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </div>
   );
};
