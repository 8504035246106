import { CredentialResponse, googleLogout } from "@react-oauth/google";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { constants_paths } from "../../constants/path";
import { useAppContext } from "../../contexts/AppContext";
import { useCredential } from "./useCredential";

export const useSosAuth = () => {
   const { setCredential, removeCredential } = useCredential();
   const { appDispatch } = useAppContext();
   const redirect = useSearchParams()[0].get("redirect");
   const nav = useNavigate();
   const curPath = window.location.pathname;

   const logIn = ({ credential }: CredentialResponse) => {
      if (!credential) return;
      setCredential(credential);
      appDispatch({ overwrite: { credential } });
      const path = redirect || constants_paths.HOME;
      nav(path);
   };
   const logOut = () => {
      googleLogout();
      removeCredential();
      appDispatch({ overwrite: { credential: "" } });
      nav({
         pathname: constants_paths.SIGN_IN,
         search: curPath !== constants_paths.SIGN_IN ? createSearchParams({ redirect: curPath }).toString() : undefined,
      });
   };
   return { logIn, logOut };
};
