import axios, { CreateAxiosDefaults } from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { constants_paths } from "../../constants/path";
import { useSosAuth } from "../auth/useSosAuth";

export const useCreateAxios = ({ config, redirectOnError }: TuseCreateAxiosParams) => {
   const nav = useNavigate();
   const createdAxios = axios.create(config);
   const { logOut } = useSosAuth();
   useEffect(() => {
      if (!redirectOnError) return;
      const responseInterceptor = createdAxios.interceptors.response.use(
         (response) => {
            return response;
         },
         (error) => {
            console.log({ createdAxiosError: error });
            if (error?.response?.status === 401 || error?.code === "ERR_NETWORK") logOut();
            else if (error?.response?.status === 403) nav(constants_paths.FORBIDDEN);
            // else if (error?.response?.status === 404) nav(constants_paths.SOMTHING_WRONG);
            else if (error?.response?.status === 500) nav(constants_paths.SOMETHING_WRONG);
            // else nav(constants_paths.SOMTHING_WRONG);
            return Promise.reject(error);
         }
      );
      return () => createdAxios.interceptors.response.eject(responseInterceptor);
   }, [createdAxios.interceptors.response, logOut, nav, redirectOnError]);
   return { createdAxios };
};
export type TuseCreateAxiosParams = { config: CreateAxiosDefaults<any>; redirectOnError?: boolean };
