import { usePVi_ZipCodes } from "../../../../hooks/property-viability/queries/usePVi_ZipCodes";
import classNames from "classnames/bind";
import styles from "./PViZipCodeCard.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { useState } from "react";
import { useClickOutside } from "../../../../hooks/react/useClickOutside";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useMDatabase_UpdateValue } from "../../../../hooks/database/mutations/useMDatabase_UpdateValue";

const cx = classNames.bind(styles);

type PViZipCodeCardProps = {
   zid: string;
   onClick: () => void;
   isSelected?: boolean;
};

export const PViZipCodeCard = ({ zid, onClick, isSelected }: PViZipCodeCardProps) => {
   const qZipCodes = usePVi_ZipCodes();

   const [dropdownVisible, setDropdownVisible] = useState(false);

   const { ref } = useClickOutside({
      onClickOutside: () => {
         setDropdownVisible(false);
      },
   });

   const { mutate: mutateUpdateZipToBlacklisted, isPending } = useMDatabase_UpdateValue({
      table: "sos_zipcodes",
      column: "blacklisted",
      identifiers: [{ column: "zid", id: zid }],
      onComplete: () => {
         qZipCodes.refetch();
         setDropdownVisible(false);
      },
   });

   if (!qZipCodes.data || !zid) return null;

   const { zipcodesMap } = qZipCodes.data;

   const { city, state, blacklisted } = zipcodesMap[zid];

   const handleToggleBlacklisted = () => mutateUpdateZipToBlacklisted({ value: !blacklisted });

   return (
      <div className={cx("wrapper", { selected: isSelected })}>
         <div className={cx("top")}>
            <div className={cx("topLeft")} onClick={onClick}>
               <div className={cx("zipC")}>
                  <span>ZIP Code</span>
                  <span className={cx("tittle")}>{zid}</span>
               </div>
               <div className={cx("locationC")}>
                  <span>Location</span>
                  <span className={cx("tittle")}>{city && state ? `${city}, ${state}` : "--"}</span>
                  <span>USA</span>
               </div>
            </div>

            <button
               title="menu"
               type="button"
               className={cx("img-icon")}
               onClick={() => setDropdownVisible(!dropdownVisible)}
            >
               <img src={CF_CDN_URL("/assets/property_viability/more_horiz_neutral500.svg")} alt="" />
            </button>

            <div className={cx("dropdownC", { dropdownVisible })} ref={ref}>
               <div className={cx("dropdown-menu")}>
                  <SHrSosButton
                     type="text"
                     buttonProps={{ className: cx("dropdown-item"), onClick: handleToggleBlacklisted }}
                  >
                     {isPending ? (
                        <div className={cx("loading")}>
                           <SHrSpinner />
                        </div>
                     ) : (
                        <>
                           <img src={CF_CDN_URL("/assets/zipcode/warning.svg")} alt="" />
                           <span> {blacklisted ? "Remove from Blacklist" : "Move to Blacklist"}</span>
                        </>
                     )}
                  </SHrSosButton>
               </div>
            </div>
         </div>

         {blacklisted && <div className={cx("bot")}>Blacklisted</div>}
      </div>
   );
};
