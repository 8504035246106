import ImageViewer from "awesome-image-viewer";
import classNames from "classnames/bind";
import styles from "./SoSImageViewer.module.scss";

const cx = classNames.bind(styles);

export const SoSImageViewer = ({ images }: TSoSImageViewerProps) => {
   const openImageViewer = (index: number) => {
      new ImageViewer({
         images: images.map((url) => ({ mainUrl: url })),
         currentSelected: index,
         isZoomable: true,
      });
   };
   return (
      <div className={cx("imgeList")}>
         {images?.map((image, i) => (
            <img key={i} src={image} alt="" onClick={() => openImageViewer(i)} />
         ))}
      </div>
   );
};

type TSoSImageViewerProps = {
   images: string[];
};
