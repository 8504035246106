import classNames from "classnames/bind";
import { constants_paths } from "../../../constants/path";
import styles from "./NavVertical.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { NavLink, useLocation } from "react-router-dom";
import { useAppContext } from "../../../contexts/AppContext";
import { apps, TApp } from "../../../constants/apps";

const cx = classNames.bind(styles);
const NavVertical = () => {
   const { appState, appOverwrite } = useAppContext();

   const { currentUser, isShowVerticalNavigation } = appState;

   const { subscribed_apps } = currentUser;

   const subscribeMenu = apps?.filter((a) => subscribed_apps?.includes(a.id));

   return (
      <div className={cx("contentC", { expandedC: isShowVerticalNavigation })}>
         <MapNavItems menuList={mainMenuList} />
         <div className={cx("divider")} />
         <MapNavItems menuList={subscribeMenu} />
         <button
            type="button"
            className={cx(`expandBtnC`, { active: isShowVerticalNavigation })}
            onClick={() => appOverwrite({ isShowVerticalNavigation: !isShowVerticalNavigation })}
         >
            <img src={CF_CDN_URL("/assets/double_arrow_right_primary.svg")} alt="toggle-btn" />
         </button>
      </div>
   );
};

export default NavVertical;

const MapNavItems = ({ menuList }: { menuList: TApp[] }) => {
   const location = useLocation();
   const { appOverwrite } = useAppContext();
   return (
      <ul>
         {menuList.map((item, i) => (
            <li key={i + item.name}>
               <NavLink
                  to={item.path}
                  className={cx({ active: location.pathname === item.path })}
                  onClick={() => appOverwrite({ isShowVerticalNavigation: false })}
               >
                  <img src={CF_CDN_URL(`/assets/${item.icon}`)} alt="" />
                  <span>{item.name}</span>
               </NavLink>
            </li>
         ))}
      </ul>
   );
};

const mainMenuList: TApp[] = [
   { path: constants_paths["HOME"], name: "Home", icon: "nav_home.svg", id: "" },
   { path: constants_paths["APPLICATION_LIBRARY"], icon: "widgets_black.svg", name: "Application Library", id: "" },
];
