import styles from "./DevJim.module.scss";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSInputSelect, TSoSInputSelect_option } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { useState } from "react";
import { SoSDataPoint } from "../../components/SoSDataPoint/SoSDataPoint";
import {
   database_helpers_createPayloadHelper as db,
   database_helpers_createPayloadHelper as genDbCb,
} from "../../hooks/database/mutations/utils";
import { useMDatabase_UploadFiles } from "../../hooks/database/mutations/useMDatabase_UploadFiles";
import { useMDatabase_DownloadFile } from "../../hooks/database/mutations/useMDatabase_DownloadFile";
import { TPgSoS_SoSUtilitiesAllowances_Schema_Data } from "@simplyhomes/utils/dist/types/pg/sos/utilities-allowances";
import { TPgSoS_SoSUnits_Schema_UtilitiesConfig } from "@simplyhomes/utils/dist/types/pg/sos/units";
import Timeline, {
   DateHeader,
   SidebarHeader,
   TimelineGroupBase,
   TimelineHeaders,
   TimelineItemBase,
} from "react-calendar-timeline";
import moment from "moment";
import "./DevJim.scss";
import { Outlet, useParams } from "react-router-dom";
// import DocViewer from "react-doc-viewer";
export const DevJim = () => {
   const data = {} as TPgSoS_SoSUtilitiesAllowances_Schema_Data;
   const config = [{ groupTitle: "Heating", lineTitle: "Natural Gas" }] as TPgSoS_SoSUnits_Schema_UtilitiesConfig;
   const responsibility = {
      gas: true,
      sewer: true,
      trash: true,
      water: true,
      appliances: false,
      electricity: true,
   };
   const bed = 3;

   return (
      <div className={`${styles.contentC}`}>
         <div>
            <div>
               <span>1</span>
               <span>2</span>
               <span>3</span>
               <span>4</span>
            </div>
            <div>
               <b>a</b>
               <b>b</b>
            </div>
         </div>

         {/* <TestSubRoute /> */}
         <div className={`${styles.hidden}`}>
            {/* <TestDataPoint /> */}
            {/* <TestInputSelectTags />
            <TestTable />
            <TestFileUpload />
            <TestFileDownload />
            <TestConfirmWrapper /> */}
         </div>
      </div>
   );
};
const TestSubRoute = () => {
   const { jom } = useParams();
   console.log({ jom });
   return (
      <div>
         <Outlet />
      </div>
   );
};
const TestTable = () => {
   const groups: TimelineGroupBase[] = [
      { id: 1, title: "group 1" },
      { id: 2, title: "group 2" },
   ];
   const defaultTimeStart = moment().add(-1, "month").startOf("month").toDate();
   const defaultTimeEnd = moment().add(1, "month").startOf("month").toDate();
   const items: TimelineItemBase<moment.Moment>[] = [
      {
         id: 1,
         group: 1,
         title: "item 1",
         start_time: moment(),
         end_time: moment().add(1, "day"),
      },
      {
         id: 2,
         group: 2,
         title: "item 2",
         start_time: moment().add(-0.5, "day"),
         end_time: moment().add(0.5, "day"),
      },
      {
         id: 3,
         group: 1,
         title: "item 3",
         start_time: moment().add(2, "day"),
         end_time: moment().add(3, "day"),
      },
   ];
   return (
      <div className={`${styles.testTable}`}>
         <Timeline
            stackItems
            groups={groups}
            items={items}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            className={`${styles.timeline}`}
            minZoom={1000 * 60 * 60 * 24 * 30}
            minResizeWidth={74}
            lineHeight={128}
            itemHeightRatio={0.25}
            sidebarWidth={320}
            useResizeHandle
         >
            <TimelineHeaders className="sticky">
               <SidebarHeader>
                  {({ getRootProps }) => {
                     return (
                        <div {...getRootProps()} className={`${styles.header}`}>
                           Active contracts
                        </div>
                     );
                  }}
               </SidebarHeader>
               <DateHeader unit="primaryHeader"></DateHeader>
               <DateHeader unit="day" labelFormat="dddd" />
            </TimelineHeaders>
         </Timeline>
      </div>
   );
};

const TestDataPoint = () => {
   const [value, setValue] = useState(["option1", "option2"]);
   // const db = genDbCb("sos_properties", [{ column: "pid", id: "1" }], () => {});
   const db = genDbCb("sos_leads", [{ column: "lid", id: "80" }]);
   const options = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
   ];
   return (
      <div>
         <SoSDataPoint
            options_isMulti
            options={options}
            title="Test"
            type="bold-title"
            value={value}
            database={db("next_actions")}
            buttons={[{ title: "Test", onClick: () => alert("Test"), loading: false }]}
         />
      </div>
   );
};

const TestFileUpload = () => {
   const [files, setFiles] = useState<FileList | null>(null);
   const db_P = db("sos_properties", [{ column: "pid", id: "ChIJ1TYiDHPQNIgRTDK3xPmzJfw" }]);
   const mUploadFiles = useMDatabase_UploadFiles(db_P("scope_of_work"));
   const handleUpload = () => {
      if (!files) return;
      mUploadFiles.mutate({ files });
   };
   return (
      <div>
         <input title="file-upload" type="file" onChange={(e) => setFiles(e.target.files)} />
         <div>{files?.length} files selected</div>
         <button title="upload" onClick={handleUpload} type="button">
            Upload
         </button>
      </div>
   );
};

const TestFileDownload = () => {
   const mDownloadFile = useMDatabase_DownloadFile({ fuid: 1, fileNameAndExtension: "test.svg" });
   return (
      <div>
         <div>Is Downloading: {(!!mDownloadFile.isMutating).toString()}</div>
         <button type="button" title="download" onClick={() => mDownloadFile.mutate()}>
            Download File
         </button>
      </div>
   );
};

const TestInputSelectTags = () => {
   const [selected, setSelected] = useState<TSoSInputSelect_option[]>([]);
   const options: TSoSInputSelect_option[] = [
      { label: "Option 1", value: "option1" },
      { label: "Option 2", value: "option2" },
      { label: "Option 3", value: "option3" },
      { label: "Option 4", value: "option4" },
   ];
   return (
      <div>
         <SoSInputSelect isMulti value={selected} onChange={setSelected} options={options} />
      </div>
   );
};

const TestConfirmWrapper = () => (
   <SoSConfirmWrapper type="default" onCancel={() => alert("Cancelled")} onConfirm={() => alert("CONFIRMED")}>
      <SHrSosButton type="dangerous" buttonProps={{ onClick: () => alert("DO NOT USE ONCLICK ON CHILDREN") }}>
         Delete
      </SHrSosButton>
   </SoSConfirmWrapper>
);
