import { createContext, useContext, useEffect, useReducer } from "react";

export class ConvoState {
   constructor(init?: Partial<ConvoState>) {
      if (init) Object.assign(this, init);
   }
   tid: number = 0;
   tags: string[] = [];
   q: string = "";
   showThreadDetailsModal: boolean = false;
}

type ConvoAction = { overwrite: Partial<ConvoState> };

const ConvoContextDefault = { convoState: new ConvoState(), convoDispatch: () => {} };
type ConvoContextType = { convoState: ConvoState; convoDispatch: React.Dispatch<ConvoAction> };
const ConvoContext = createContext<ConvoContextType>(ConvoContextDefault);

const convoReducer = (state: ConvoState, action: ConvoAction): ConvoState => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   return state;
};
type ConvoProviderProps = { children: React.ReactNode; initialState?: Partial<ConvoState> };
export const ConvoProvider = ({ children, initialState }: ConvoProviderProps) => {
   const [convoState, convoDispatch] = useReducer(convoReducer, new ConvoState(initialState));
   useEffect(() => {
      if (initialState) {
         convoDispatch({ overwrite: initialState });
      }
   }, [initialState]);

   return <ConvoContext.Provider value={{ convoState, convoDispatch }}>{children}</ConvoContext.Provider>;
};

export const useConvoContext = () => useContext(ConvoContext);
