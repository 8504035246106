import classNames from "classnames/bind";
import styles from "./TransactionInspectionTab.module.scss";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import SoSSlider, { SoSSlider_Item } from "../../../components/SoSSlider/SoSSlider";
import { useEffect, useState } from "react";
import { TransactionScheduleInspectionModal } from "../TransactionScheduleInspectionModal/TransactionScheduleInspectionModal";
import { TransactionScheduleWalkThroughModal } from "../TransactionScheduleWalkThroughModal/TransactionScheduleWalkThroughModal";
import { useTransactionOptions } from "../../../hooks/transactions/hooks/useTransactionOptions";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
import { useQ_Users } from "../../../hooks/queries/user/useQ_Users";
import {
   SoSUploaderContainer,
   SoSUploaderContainerFileType,
} from "../../../components/common/SoSUploaderContainer/SoSUploaderContainer";
import { useMDatabase_UploadFiles } from "../../../hooks/database/mutations/useMDatabase_UploadFiles";
import { useMDatabase_RemoveFile } from "../../../hooks/database/mutations/useMDatabase_DeleteFile";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";

const cx = classNames.bind(styles);

export const formatDate = (dateString: string): string => {
   if (!dateString) return "";

   const date = new Date(dateString);

   const day = String(date.getDate()).padStart(2, "0");
   const month = String(date.getMonth() + 1).padStart(2, "0");
   const year = date.getFullYear();

   return `${day}/${month}/${year}`;
};

export const TransactionInspectionTab = () => {
   const { transaction, property, qGetTransaction } = useSelectedTransactionAndProperty();

   const { qInspectionStatusOptions } = useTransactionOptions();

   const qUsers = useQ_Users();

   const [values, setValues] = useState<Record<string, SoSUploaderContainerFileType[]> | null>(null);

   const db_P = dbcb("sos_properties", [{ column: "pid", id: property?.pid || "" }], () => {
      qGetTransaction?.refetch();
   });
   const mUploadFiles = useMDatabase_UploadFiles(db_P("scope_of_work"));
   const mRemoveFile = useMDatabase_RemoveFile(db_P("scope_of_work"));

   useEffect(() => {
      if (!transaction) return;
      const { properties } = transaction;
      const staticFilesTransformed = properties.reduce((acc, p) => {
         acc[p.pid] = p.scope_of_works || [];
         return acc;
      }, {} as Record<string, SoSUploaderContainerFileType[]>);
      setValues(staticFilesTransformed);
   }, [transaction]);

   const userOptions = qUsers.data?.users.map((u) => ({ label: u.name, value: u.uid })) || [];

   const [isOpenScheduleInspectionModal, setOpenScheduleInspectionModal] = useState(false);

   const [isOpenScheduleWalkThroughModal, setOpenScheduleWalkThroughModal] = useState(false);

   if (!transaction) return null;

   const { properties } = transaction;

   const { inspection, walkthrough } = property || {};

   const handleUploadFiles = (files: FileList) => {
      const newFiles: SoSUploaderContainerFileType[] = Array.from(files).map((file) => ({
         fuid: 0,
         uploading: true,
         file_name: file.name,
      }));

      const statisFilesSelected = values?.[property?.pid || properties[0].pid] || [];

      statisFilesSelected.push(...newFiles);

      mUploadFiles.mutate({ files });

      setValues({ ...values, [property?.pid || properties[0].pid]: statisFilesSelected });
   };

   const handleRemoveFile = (fuid: number) => {
      const fileToRemove = values?.[property?.pid || properties[0].pid]?.find((f) => f.fuid === fuid);

      if (!fileToRemove) return;

      fileToRemove.uploading = true;

      mRemoveFile.mutate({ fuid });

      setValues({
         ...values,
         [property?.pid || properties[0].pid]: values?.[property?.pid || properties[0].pid]!,
      });
   };

   return (
      <div className={cx("container")}>
         <TransactionPropertySwitch properties={properties} />

         <div className={cx("inspectionC")}>
            <div className={cx("headingAndBtn")}>
               <span className={cx("title")}>Acquisition Inspection</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setOpenScheduleInspectionModal(true),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                  </div>
                  Schedule an inspection
               </SHrSosButton>
            </div>

            <div className={cx("inspectionDetailC")}>
               <div className={cx("inspectionStatusC")}>
                  <div className={cx("asset")}>
                     <SoSDataPoint
                        type="bold-title"
                        title="Inspection Status"
                        backGroundColor={qInspectionStatusOptions.find((o) => o.label === inspection?.status)?.color}
                        value={inspection?.status}
                     />
                  </div>
                  <div className={cx("asset")}>
                     <SoSDataPoint type="bold-title" title="Current Occupancy" value={inspection?.current_occupancy} />
                  </div>
                  <div className={cx("asset", "whiteBg")}>
                     <SoSDataPoint
                        type="bold-value"
                        title="Inspection Cost"
                        format="currency"
                        value={inspection?.inspection_cost}
                     />
                  </div>
               </div>

               <div className={cx("inspectionStatusC")}>
                  <div className={cx("asset")}>
                     <SoSDataPoint
                        type="bold-title"
                        title="Date"
                        value={formatDate(inspection?.inspection_date || "")}
                     />
                  </div>
                  <div className={cx("asset")}>
                     <SoSDataPoint
                        type="bold-title"
                        title="Reno Person"
                        value={userOptions.find((o) => o.value === inspection?.reno_person)?.label}
                        options={userOptions}
                     />
                  </div>
                  <div className={cx("asset")}>
                     <SoSDataPoint
                        type="bold-title"
                        title="Assigned POC"
                        value={userOptions.find((o) => o.value === inspection?.assigned_poc)?.label}
                        options={userOptions}
                     />
                  </div>
               </div>

               <hr className={cx("divide")} />

               <div className={cx("notesC")}>
                  <div className={cx("photosC")}>
                     <div className={cx("titleAndBtn")}>
                        <span className={cx("title")}>
                           <b>Photos</b>
                        </span>
                        <SHrSosButton
                           type="text"
                           buttonProps={{
                              className: cx("addPhotosBtn"),
                           }}
                        >
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
                           </div>
                           Add photo
                        </SHrSosButton>
                     </div>

                     <div className={cx("sliderPhotosC")}>
                        <SoSSlider items={photos} width={80} height={80} />
                     </div>

                     <textarea onChange={(v) => {}} placeholder="Add some description" value={""} readOnly />
                  </div>
                  <div className={cx("divider-container")}>
                     <hr className={cx("divider")} />
                  </div>
                  <div className={cx("SOWC")}>
                     <div className={cx("headingC")}>
                        <span>
                           <b>SOW</b>
                        </span>
                     </div>
                     <SoSUploaderContainer
                        type="files"
                        values={values?.[property?.pid || properties[0].pid] || []}
                        onUpload={(files) => handleUploadFiles(files)}
                        onRemove={handleRemoveFile}
                     >
                        <SHrSosButton
                           type="text"
                           buttonProps={{
                              className: cx("uploadFileBtn"),
                              disabled: !!mUploadFiles.isMutating || !!mRemoveFile.isMutating,
                           }}
                        >
                           Upload a file
                        </SHrSosButton>
                     </SoSUploaderContainer>
                  </div>
               </div>

               <hr className={cx("divide")} />

               <div className={cx("notesC")}>
                  <div className={cx("noteC")}>
                     <span>
                        <b>Access Details</b>
                     </span>
                     <textarea
                        placeholder="Note to the assignee"
                        value={inspection?.access_details || "No access_details"}
                        readOnly
                     />
                  </div>
                  <div className={cx("divider-container", "note")}>
                     <hr className={cx("divider")} />
                  </div>
                  <div className={cx("noteC")}>
                     <span>
                        <b>Notes</b>
                     </span>
                     <textarea placeholder="Note to the assignee" value={inspection?.notes || "No notes"} readOnly />
                  </div>
               </div>
            </div>
         </div>

         <div className={cx("walkThroughC")}>
            <div className={cx("headingAndBtn")}>
               <span className={cx("title")}>Closing Walkthrough</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setOpenScheduleWalkThroughModal(true),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
                  </div>
                  Schedule a walkthrough
               </SHrSosButton>
            </div>

            <div className={cx("inspectionDetailC")}>
               <div className={cx("inspectionStatusC")}>
                  <div className={cx("asset")}>
                     <SoSDataPoint
                        type="bold-title"
                        title="Walkthrough Status"
                        backGroundColor={qInspectionStatusOptions.find((o) => o.label === walkthrough?.status)?.color}
                        value={walkthrough?.status}
                     />
                  </div>
                  <div className={cx("asset")}>
                     <SoSDataPoint
                        type="bold-title"
                        title="Date"
                        value={formatDate(walkthrough?.walkthrough_date || "")}
                     />
                  </div>
                  <div className={cx("asset")}>
                     <SoSDataPoint
                        type="bold-title"
                        title="Reno Person"
                        value={userOptions.find((o) => o.value === walkthrough?.reno_person)?.label}
                        options={userOptions}
                     />
                  </div>
                  <div className={cx("asset")}>
                     <SoSDataPoint
                        type="bold-title"
                        title="Assigned POC"
                        value={userOptions.find((o) => o.value === walkthrough?.assigned_poc)?.label}
                        options={userOptions}
                     />
                  </div>
               </div>

               <div className={cx("notesC")}>
                  <div className={cx("photosC")}>
                     <div className={cx("titleAndBtn")}>
                        <span className={cx("title")}>
                           <b>Photos</b>
                        </span>
                        <SHrSosButton
                           type="text"
                           buttonProps={{
                              className: cx("addPhotosBtn"),
                           }}
                        >
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
                           </div>
                           Add photo
                        </SHrSosButton>
                     </div>

                     <div className={cx("sliderPhotosC")}>
                        <SoSSlider items={photos} width={80} height={80} />
                     </div>

                     <textarea onChange={(v) => {}} placeholder="Add some description" value={""} readOnly />
                  </div>
                  <div className={cx("divider-container")}>
                     <hr className={cx("divider")} />
                  </div>
                  <div className={cx("SOWC")}>
                     <div className={cx("headingC")}>
                        <span>
                           <b>Amendment</b>
                        </span>
                     </div>

                     <SoSUploaderContainer
                        type="files"
                        values={values?.[property?.pid || properties[0].pid] || []}
                        onUpload={(files) => handleUploadFiles(files)}
                        onRemove={handleRemoveFile}
                     >
                        <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                           Upload a file
                        </SHrSosButton>
                     </SoSUploaderContainer>
                  </div>
               </div>

               <hr className={cx("divide")} />

               <div className={cx("noteC")}>
                  <span>
                     <b>Notes</b>
                  </span>
                  <textarea placeholder="Note to the assignee" value={walkthrough?.notes || "No notes"} />
               </div>
            </div>
         </div>

         <SHrPopup show={isOpenScheduleInspectionModal}>
            <TransactionScheduleInspectionModal onClose={() => setOpenScheduleInspectionModal(false)} />
         </SHrPopup>

         <SHrPopup show={isOpenScheduleWalkThroughModal}>
            <TransactionScheduleWalkThroughModal onClose={() => setOpenScheduleWalkThroughModal(false)} />
         </SHrPopup>
      </div>
   );
};

const photos: SoSSlider_Item[] = [
   { type: "image", src: CF_CDN_URL("/assets/transaction/transProperty.png") },
   {
      type: "image",
      src: CF_CDN_URL("/assets/transaction/transProperty.png"),
   },
];
