import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";
import { useAppContext } from "../../../contexts/AppContext";
import { useWebSocket_CatchEvent } from "../../webSocket/useWebSocket_CatchEvent";

export const useQSoS_Notifications = () => {
   const { sosAxios } = useSosAxios();
   const { sub } = useAppContext().appState.currentUser;
   const query = useQuery({
      enabled: !!sub,
      queryKey: [`useQSoS_Notifications`],
      refetchInterval: 5 * 60 * 1000,
      queryFn: () =>
         sosAxios
            .get(`/notifications`)
            .then<TNofs_GetNotifications_Response>(({ data }: { data: TNofs_GetNotifications_Response }) => {
               return data;
            }),
   });
   useWebSocket_CatchEvent({ room: "refresh:Notifications:", id: sub }, () => query.refetch());
   return query;
};

export type TNofs_GetNotifications_Response_Notification_Data = {
   tid: number;
   commenter: string;
   comment: string;
   pid?: string;
   lid?: number;
};

export type TNofs_GetNotifications_Notification = {
   nid: number;
   type: "new-comment-in-subscribed-thread" | "mentioned-in-comment" | "mention-in-thread";
   read: boolean;
   data: TNofs_GetNotifications_Response_Notification_Data;
   created_at: string;
};

export type TNofs_GetNotifications_Response = {
   payload: {
      notifications: TNofs_GetNotifications_Notification[];
   };
};
