import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../contexts/ToastContext";
import { useSosAxios } from "../../axios/useSosAxios";
import { Database_Column, Database_Table } from "./utils";

export const useMDatabase_UploadImages = (p?: useMDatabase_UploadImages_Params) => {
   const { table, column, identifiers, onComplete = () => {} } = p || {};
   const { sosAxios } = useSosAxios({ redirectOnError: false });
   const { toastDispatch } = useToastContext();
   const mutationKey = [table, column, identifiers];

   const mutation = useMutation({
      mutationKey,
      onSuccess: () => {
         onComplete(true);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "success", message: "Images Uploaded" } });
      },
      onError: () => {
         onComplete(false);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "error", message: "Invalid Images" } });
      },
      mutationFn: ({ images }: { images: FileList }) => {
         const formData = new FormData();

         Array.from(images).forEach((image) => formData.append("images[]", image));

         return sosAxios
            .post("/database/images", formData, {
               headers: { "Content-Type": "multipart/form-data" },
               params: { table, column, identifiers },
            })
            .then<DatabaseService_UploadImages_Response_Dto>(({ data }) => data);
      },
   });
   const isMutating = useIsMutating({ mutationKey });

   return { ...mutation, isMutating };
};

export type useMDatabase_UploadImages_Params = {
   table: Database_Table;
   column: Database_Column;
   identifiers: { column: string; id: string | number }[];
   onComplete?: (isSuccess: boolean) => void;
};

export class DatabaseService_UploadImages_Response_Dto {
   iuids!: string[];
}
