import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { usePViQueryParams } from "../../hooks/usePViQueryParams";
import { usePViContext } from "../../../../contexts/PViContext";
import { useSoSNavigate } from "../../../navigate/useSoSNavigate";

export const useMPVi_QuickPickProperty = () => {
   const { nav } = useSoSNavigate();

   const { sosAxios } = useSosAxios();

   const { filters } = usePViQueryParams();

   const { pviState, pviOverwrite } = usePViContext();

   const { excludedPropertyIds: currentExcludedPropertyIds, pid } = pviState;

   const newExcludedPropertyIds = [...currentExcludedPropertyIds];

   if (pid && !currentExcludedPropertyIds.includes(pid)) {
      newExcludedPropertyIds.push(pid);
   }

   const mutationKey = ["useMPVi_QuickPickProperty"];

   const isMutating = useIsMutating({ mutationKey });

   const mutation = useMutation({
      mutationKey,
      mutationFn: () =>
         sosAxios
            .get(`/property-viability/quick-pick`, {
               params: { ...filters, excludedPropertyIds: newExcludedPropertyIds },
            })
            .then(({ data }: { data: PVi_GetQuickPickProperty_Response_Dto }) => {
               const { pid } = data;
               if (pid === "-1" || !pid) {
                  nav("");
                  pviOverwrite({ showDetailsModal: false });
               } else {
                  nav(pid);
                  pviOverwrite({ excludedPropertyIds: newExcludedPropertyIds, showDetailsModal: true });
               }
               return data;
            }),
   });

   return { ...mutation, isMutating };
};

export class PVi_GetQuickPickProperty_Response_Dto {
   pid!: string;
}
