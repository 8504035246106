import classNames from "classnames/bind";
import styles from "./TransactionFinancingTab.module.scss";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
import { useTransactionOptions } from "../../../hooks/transactions/hooks/useTransactionOptions";
import { SoSUploaderContainer } from "../../../components/common/SoSUploaderContainer/SoSUploaderContainer";
import { SHrSosButton } from "@simplyhomes/react";

const cx = classNames.bind(styles);

export const TransactionFinancingTab = () => {
   const { transaction } = useSelectedTransactionAndProperty();
   const { qFinancingStatusOptions } = useTransactionOptions();

   if (!transaction) return null;

   const { properties, financing_status } = transaction;

   return (
      <div className={cx("container")}>
         <TransactionPropertySwitch properties={properties} />
         <div className={cx("financingC")}>
            <span>(Activity Name)</span>
            <div className={cx("financing")}>
               <div className={cx("financingStatus")}>
                  <SoSDataPoint
                     type={"bold-title"}
                     title="Financing Status"
                     value={financing_status}
                     options={qFinancingStatusOptions}
                     backGroundColor={qFinancingStatusOptions?.find((v) => v.label === financing_status)?.color}
                  />
               </div>
               <hr className={cx("divide")} />
               <div className={cx("documentC")}>
                  <div className={cx("headingC")}>
                     <span>
                        <b>Related Document</b>
                     </span>
                  </div>

                  <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                     <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                        Upload a file
                     </SHrSosButton>
                  </SoSUploaderContainer>
               </div>
            </div>
         </div>
         <hr />
         <div className={cx("financingC")}>
            <span>Acquisition</span>
            <div className={cx("financing")}>
               <div className={cx("financingStatus")}>
                  <SoSDataPoint type={"bold-title"} title="Acquisition Status" value={"(status)"} />
               </div>
               <hr className={cx("divide")} />
               <div className={cx("documentC")}>
                  <div className={cx("headingC")}>
                     <span>
                        <b>Related Document</b>
                     </span>
                  </div>

                  <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                     <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                        Upload a file
                     </SHrSosButton>
                  </SoSUploaderContainer>
               </div>
            </div>
         </div>
      </div>
   );
};
