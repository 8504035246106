import { useAppContext } from "../../contexts/AppContext";
import { useWebSocketContext } from "../../contexts/WebSocketContext";
import { TuseCreateAxiosParams, useCreateAxios } from "./useCreateAxios";

export const useSosAxios = (p?: TuseSosAxiosParams) => {
   const { redirectOnError } = p || {};
   const { credential } = useAppContext().appState;
   const { socket } = useWebSocketContext();
   const { createdAxios: sosAxios } = useCreateAxios({
      config: {
         baseURL: process.env.REACT_APP_SOS_API_URL + "/sos",
         headers: { authorization: credential, "socket-id": socket?.id },
      },
      redirectOnError: redirectOnError == null ? true : redirectOnError,
   });

   return { sosAxios };
};

type TuseSosAxiosParams = Pick<TuseCreateAxiosParams, "redirectOnError">;
