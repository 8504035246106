import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useQPVi_Properties } from "../../queries/useQPVi_Properties";

export const useMPVi_PropertyBulk = ({ onSuccess, onError }: TuseMPVi_PropertyBulkProps) => {
   const { sosAxios } = useSosAxios();

   const qProperties = useQPVi_Properties();

   const mutation = useMutation({
      onSuccess,
      onError,
      mutationFn: (p: PVi_BulkProperties_Params) =>
         sosAxios
            .post(`/property-viability/properties/bulk`, { ...p })
            .then(({ data }: { data: PVi_BulkProperties_Response }) => {
               qProperties.refetch();
               return data;
            }),
   });
   return { ...mutation };
};

export type TuseMPVi_PropertyBulkProps = {
   onSuccess: (data: PVi_BulkProperties_Response) => void;
   onError: (error: any) => void;
};

export type PVi_BulkProperties_Response = {
   pids: string[];
};

class PVi_CreateSource_Body_Dto_Property_Unit {
   bed!: number;
   bath!: number;
}

export type PVi_BulkProperties_Property = {
   sqft: number;
   address: string;
   price: number;
   units: PVi_CreateSource_Body_Dto_Property_Unit[];
   city: string;
};

export type PVi_BulkProperties_Params = {
   source_source: string;
   properties: PVi_BulkProperties_Property[];
};
