import { useEffect } from "react";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useQTrans_Transaction } from "../queries/useQTrans_Transaction";

export const useSelectedTransactionAndProperty = () => {
   const {
      transactionsState: { pidSelected },
      transactionsDispatch,
   } = useTransactionsContext();

   const qGetTransaction = useQTrans_Transaction();

   const transaction = qGetTransaction.data?.transaction;

   useEffect(() => {
      const firstPid = transaction?.properties[0].pid;
      transactionsDispatch({ overwrite: { pidSelected: firstPid } });
   }, [transaction?.properties, transactionsDispatch]);

   if (!transaction) return { property: null, transaction: null };

   const { properties } = transaction;

   const property = properties.find((p) => p.pid === pidSelected);

   return { property, transaction, pidSelected, qGetTransaction };
};
