import classNames from "classnames/bind";
import styles from "./PViLead.module.scss";
import { CF_CDN_URL } from "../../../../../utils/CF_CDN_URL";
import { useQPVi_Property } from "../../../../../hooks/property-viability/queries/useQPVi_Property";
import { formatDate } from "../../../../OffersPage/OffersLeadCard/OffersLeadCard";
import { useSoSOptions_Offers_Leads_Statuses } from "../../../../../hooks/queries/options/useQSoS_Options";
import { useQ_Users } from "../../../../../hooks/queries/user/useQ_Users";

const cx = classNames.bind(styles);

export const PViLead = () => {
   const qPVi_Property = useQPVi_Property();

   const qUsers = useQ_Users();

   const { leads = [] } = qPVi_Property.data || {};

   const qLeadStatusesOptions = useSoSOptions_Offers_Leads_Statuses().data?.options || [];

   const statusSelected = leads.map(
      (lead) => qLeadStatusesOptions?.find((status) => status.value === lead.status)?.label
   );

   const userOptions = qUsers.data?.users.map((u) => ({ label: u.name, value: u.uid })) || [];

   return (
      <div className={cx("container")}>
         <div className={cx("list")}>
            {!leads.length ? (
               <div className={cx("noData")}>
                  <span>No leads found</span>
               </div>
            ) : (
               leads.map((lead) => (
                  <div className={cx("card")}>
                     <div className={cx("name")}>
                        <span>{lead.name}</span>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/open_in_new_black.svg")} alt="" />
                        </div>
                     </div>
                     <div className={cx("infor")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_viability/home_round_black.svg")} alt="" />
                        </div>
                        <span>Properties</span>
                        <div className={cx("value")}>{lead.properties_count}</div>
                     </div>
                     <div className={cx("infor")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_viability/incomplete_circle_black.svg")} alt="" />
                        </div>
                        <span>Status</span>
                        <div
                           className={cx("valueStatus")}
                           style={{
                              backgroundColor: qLeadStatusesOptions?.find((status) => status.value === lead.status)
                                 ?.color,
                           }}
                        >
                           {statusSelected}
                        </div>
                     </div>
                     <div className={cx("infor")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_viability/event_black.svg")} alt="" />
                        </div>
                        <span>Created at</span>
                        <div className={cx("value")}>{formatDate(lead.created_at)}</div>
                     </div>
                     <div className={cx("infor")}>
                        <div className={cx("img-icon")}>
                           <img src={CF_CDN_URL("/assets/property_viability/person_black.svg")} alt="" />
                        </div>
                        <span>Assignee</span>
                        <div className={cx("value")}>
                           {lead.assignees
                              .map((assignee) => {
                                 const user = userOptions.find((o) => o.value === assignee);
                                 return user ? user.label : "No assignee";
                              })
                              .join(", ")}
                        </div>
                     </div>
                  </div>
               ))
            )}
         </div>
      </div>
   );
};
