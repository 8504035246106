import classNames from "classnames/bind";
import styles from "./TransactionActiveContractModal.module.scss";
import { SHrSosTabsBar, SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useState } from "react";

import { SoSConversation } from "../../SoSConversation/SoSConversation";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useQTrans_Transactions } from "../../../hooks/transactions/queries/useQTrans_Transactions";
import { TransactionOverviewTab } from "../TransactionOverviewTab/TransactionOverviewTab";
import { TransactionCalendarTab } from "../TransactionCalendarTab/TransactionCalendarTab";
import { TransactionTitleTab } from "../TransactionTitleTab/TransactionTitleTab";
import { TransactionPropertiesTab } from "../TransactionPropertiesTab/TransactionPropertiesTab";
import { formatDate, TransactionInspectionTab } from "../TransactionInspectionTab/TransactionInspectionTab";
import { useQTrans_Transaction } from "../../../hooks/transactions/queries/useQTrans_Transaction";
import { TransactionFinancingTab } from "../TransactionFinancingTab/TransactionFinancingTab";
import { TransactionInsuranceTab } from "../TransactionInsuranceTab/TransactionInsuranceTab";
import { useTransactionOptions } from "../../../hooks/transactions/hooks/useTransactionOptions";
import { TransactionUnderWritingTab } from "../TransactionUnderWritingTab/TransactionUnderWritingTab";
import { TransactionDocumentTab } from "../TransactionDocumentTab/TransactionDocumentTab";
import { useClickOutside } from "../../../hooks/react/useClickOutside";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
const cx = classNames.bind(styles);

const tabs = [
   "Overview",
   "Calendar",
   "Title",
   "Inspection",
   "Properties",
   "Underwriting",
   "Financing",
   "Insurance",
   "Document",
];

export const TransactionActiveContractModal = () => {
   const { transactionsOverwrite } = useTransactionsContext();

   const { qEMDStatusOptions } = useTransactionOptions();

   const { transaction, qGetTransaction } = useSelectedTransactionAndProperty();

   const { ref: clickOutSideRef } = useClickOutside({
      onClickOutside: () => transactionsOverwrite({ showDetailsModal: false }),
   });

   const [activeTab, setActiveTab] = useState("Overview");

   const qGetTransactions = useQTrans_Transactions();

   if (!transaction) return null;

   const { name, emd_status, emd_end_date, closing_end_date, expire_date } = transaction;

   const emdColor = qEMDStatusOptions?.find((v) => v.label === emd_status)?.color;

   const color = emdColor;

   const eventColor = () => {
      if (color === "#D6E0FF") {
         return "info";
      }
      if (color === "#D0F5E1") {
         return "positive";
      }
      if (color === "#FFE59E") {
         return "warning";
      }
      if (color === "#F2415A") {
         return "negative";
      }
   };

   return (
      <div className={cx("contentC")} ref={clickOutSideRef}>
         <div className={cx("headingC")}>
            <div className={cx("addressC")}>
               <SoSDataPoint type="bold-value" value={name} />
            </div>
            <div className={cx("close-btn")} onClick={() => transactionsOverwrite({ showDetailsModal: false })}>
               <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
            </div>
         </div>

         <div className={cx("tab")}>
            <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={setActiveTab} />
         </div>

         <div className={cx("bodyC")}>
            <div className={cx("bodyLeft")}>
               {qGetTransaction.isLoading ? (
                  <div className={cx("loading")}>
                     <SHrSpinner />
                     <span>Loading Transaction...</span>
                  </div>
               ) : (
                  <div className={cx("tabDetail")}>
                     {(activeTab === "Overview" && <TransactionOverviewTab />) ||
                        (activeTab === "Calendar" && <TransactionCalendarTab />) ||
                        (activeTab === "Title" && <TransactionTitleTab />) ||
                        (activeTab === "Inspection" && <TransactionInspectionTab />) ||
                        (activeTab === "Properties" && <TransactionPropertiesTab />) ||
                        (activeTab === "Underwriting" && <TransactionUnderWritingTab />) ||
                        (activeTab === "Financing" && <TransactionFinancingTab />) ||
                        (activeTab === "Insurance" && <TransactionInsuranceTab />) ||
                        (activeTab === "Document" && <TransactionDocumentTab />)}
                  </div>
               )}
            </div>
            <div className={cx("bodyRight")}>
               <div className={cx("eventC")}>
                  <div className={cx("eventHeading")}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/transaction/event_black.svg")} alt="" />
                     </div>
                     <span>Reminder</span>
                  </div>
                  <div className={cx("eventList")}>
                     <div className={cx("event")} style={{ background: color }}>
                        <div
                           className={cx("eventColor")}
                           style={{
                              background: `var(--${eventColor()}-500)`,
                           }}
                        ></div>
                        <div className={cx("eventDetail")}>
                           <span className={cx("eventName")}>EMD Due Date</span>
                           <span>12/9/2024</span>
                        </div>
                     </div>
                     <div className={cx("event", "green")}>
                        <div className={cx("eventColor")}></div>
                        <div className={cx("eventDetail")}>
                           <span className={cx("eventName")}>Inspection Appointment</span>
                           <span>12/9/2024</span>
                        </div>
                     </div>
                     <div className={cx("event", "green")}>
                        <div className={cx("eventColor")}></div>
                        <div className={cx("eventDetail")}>
                           <span className={cx("eventName")}>Inspection Deadline</span>
                           <span>12/9/2024</span>
                        </div>
                     </div>
                     <div className={cx("event", "red")}>
                        <div className={cx("eventColor")}></div>
                        <div className={cx("eventDetail")}>
                           <span className={cx("eventName")}>Estimated Closing Date </span>
                           <span>12/9/2024</span>
                        </div>
                     </div>
                     <div className={cx("event", "yellow")}>
                        <div className={cx("eventColor")}></div>
                        <div className={cx("eventDetail")}>
                           <span className={cx("eventName")}>Closing Walkthrough Appointment</span>
                           <span>12/9/2024</span>
                        </div>
                     </div>
                     {expire_date && (
                        <div className={cx("event", "red")} style={{ backgroundColor: "var(--negative-50)" }}>
                           <div className={cx("eventColor")} style={{ backgroundColor: "var(--negative-500)" }}></div>
                           <div className={cx("eventDetail")}>
                              <span className={cx("eventName")}>Contract Expiration</span>
                              <span>{formatDate(expire_date || "")}</span>
                           </div>
                        </div>
                     )}
                  </div>
               </div>

               <SoSConversation />
            </div>
         </div>
      </div>
   );
};
