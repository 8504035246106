import classNames from "classnames/bind";
import styles from "./PViAddNewPropertyModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SHrPopup, SHrSosButton, SHrSosTabsBar, SHrSpinner } from "@simplyhomes/react";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import {
   useSoSOptions_PVi_PropertyAddress,
   useSoSOptions_PVi_Source_Source,
} from "../../../hooks/queries/options/useQSoS_Options";
import { useState } from "react";
import { OffersCreateNewContactModal } from "../../OffersPage/OffersCreateNewContactModal/OffersCreateNewContactModal";
import { useToastContext } from "../../../contexts/ToastContext";
import {
   CreateSourceParams,
   CreateSourceParams_Property,
   useMPVi_Source_CreateSource,
} from "../../../hooks/property-viability/mutates/useMPVi_Source_CreateSource";
import { useQSOS_Contacts } from "../../../hooks/contact/queries/useQSOS_Contacts";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { FileUploader } from "react-drag-drop-files";
import PapaParse from "papaparse";
import { BULK_PROPERTY_HEADERS_BY_PURPOSE } from "../../../contexts/CsvUpContext";
import {
   PVi_GetPreBulkCheck_Response,
   useMPVi_PreBulkCheck,
} from "../../../hooks/property-viability/mutates/properties/useMPVi_PreBulkCheck";
import {
   PVi_BulkProperties_Property,
   useMPVi_PropertyBulk,
} from "../../../hooks/property-viability/mutates/properties/useMPVi_PropertyBulk";

const cx = classNames.bind(styles);

const tabs = ["Manual", "Bulk"];

export const PViAddNewPropertyModal = ({ onClose }: PViAddNewPropertyModalProps) => {
   const [selectedTab, setSelectedTab] = useState<string>("Manual");

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/property_viability/home_black.svg")} alt="" />
            </div>
            <span>Create New Properties</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         <div className={cx("tabs")}>
            <SHrSosTabsBar tabs={tabs} value={selectedTab} onChange={(v) => setSelectedTab(v)} />
         </div>

         {selectedTab === "Manual" ? <Manual onClose={onClose} /> : <Bulk onClose={onClose} />}
      </div>
   );
};

type ManualProps = {
   onClose: () => void;
};

const Manual = ({ onClose }: ManualProps) => {
   const { toastDispatch } = useToastContext();

   const qPViSourceSource = useSoSOptions_PVi_Source_Source();

   const qGetContacts = useQSOS_Contacts();

   const { mutate, isPending } = useMPVi_Source_CreateSource({
      onSuccess: () => {
         onClose();
      },
   });

   const contacts = qGetContacts.data?.contacts || [];

   const [q, setQ] = useState("");

   const [sourceSourceSelected, setSourceSourceSelected] = useState<string>("");

   const [selectedSourceContacts, setSelectedSourceContacts] = useState<number[]>([]);

   const qPViPropertyAddresses = useSoSOptions_PVi_PropertyAddress({ q });

   const getPropertyAddressesOptions = qPViPropertyAddresses.data?.options || [];

   const propertyAddressesOptions = getPropertyAddressesOptions.map((o) => ({ value: o.value, label: o.label }));

   const getSourceSourceOptions = qPViSourceSource.data?.options || [];

   const sourceSourceOptions = getSourceSourceOptions.map((o) => ({ value: o.value, label: o.label }));

   const [contactModal, setContactModal] = useState<{ isOpen: boolean; mode: "p" | "s" }>({ isOpen: false, mode: "s" });

   const [editingPropertyIndex, setEditingPropertyIndex] = useState<number | null>(null);

   const [properties, setProperties] = useState<CreateSourceParams_Property[]>([
      {
         address: "",
         price: 0,
         cids: [],
         units: [
            {
               bed: 1,
               bath: 1,
            },
         ],
      },
   ]);

   const handleSelectionContactSource = (cid: number) => {
      setSelectedSourceContacts((prevContacts) => {
         if (!prevContacts.includes(cid)) {
            return [...prevContacts, cid];
         }
         toastDispatch({ type: "ADD_TOAST", payload: { message: "Contact already selected", type: "error" } });
         return prevContacts;
      });
   };

   const handleSelectionContactProperty = (cid: number) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === editingPropertyIndex) {
               if (!prevP.cids.includes(cid)) return { ...prevP, cids: [...prevP.cids, cid] };
               toastDispatch({
                  type: "ADD_TOAST",
                  payload: {
                     message: `Contact already selected in property ${editingPropertyIndex + 1}`,
                     type: "error",
                  },
               });
            }
            return prevP;
         });
      });
   };

   const handleRemoveSouceContact = (i: number) => {
      setSelectedSourceContacts((prevContacts) => {
         return prevContacts.filter((_, index) => index !== i);
      });
   };

   const handleRemoveContactProperty = (i: number) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP) => {
            return {
               ...prevP,
               cids: prevP.cids.filter((_, indexU) => indexU !== i),
            };
         });
      });
   };

   const handleRemoveProperty = (i: number) => {
      setProperties((prevProperties) => {
         return prevProperties.filter((_, index) => index !== i);
      });
   };

   const handleRemoveUnit = (j: number) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP) => {
            return {
               ...prevP,
               units: prevP.units.filter((_, indexU) => indexU !== j),
            };
         });
      });
   };

   const handlePropertyAddressChange = (i: number, address: string) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return { ...prevP, address };
            }
            return prevP;
         });
      });
   };

   const handlePropertyPriceChange = (i: number, value: string) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return { ...prevP, price: Number(value) };
            }
            return prevP;
         });
      });
   };

   const handleAddUnitToProperty = (i: number) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return {
                  ...prevP,
                  units: [
                     ...prevP.units,
                     {
                        bed: 1,
                        bath: 1,
                     },
                  ],
               };
            }
            return prevP;
         });
      });
   };

   const handleBedroomChange = (i: number, j: number, value: string) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return {
                  ...prevP,
                  units: prevP.units.map((prevU, indexU) => {
                     if (indexU === j) {
                        return { ...prevU, bed: Number(value) };
                     }
                     return prevU;
                  }),
               };
            }
            return prevP;
         });
      });
   };

   const handleBathroomChange = (i: number, j: number, value: string) => {
      setProperties((prevProperties) => {
         return prevProperties.map((prevP, index) => {
            if (index === i) {
               return {
                  ...prevP,
                  units: prevP.units.map((prevU, indexU) => {
                     if (indexU === j) {
                        return { ...prevU, bath: Number(value) };
                     }
                     return prevU;
                  }),
               };
            }
            return prevP;
         });
      });
   };

   const handleOpenModalAddContactProperty = (i: number) => {
      setContactModal({ isOpen: true, mode: "p" });
      setEditingPropertyIndex(i);
   };

   const handleAddProperty = () => {
      setProperties((prevProperties) => {
         return [
            ...prevProperties,
            {
               address: "",
               price: 0,
               cids: [],
               units: [
                  {
                     bed: 1,
                     bath: 1,
                  },
               ],
            },
         ];
      });
   };

   const handleCreateSource = () => {
      const params: CreateSourceParams = {
         source_source: sourceSourceSelected,
         cids: selectedSourceContacts,
         properties: properties,
      };
      mutate(params);
   };

   return (
      <>
         <div className={cx("contentC")}>
            <div className={cx("sourceType")}>
               <SoSInputSelect
                  title="Source Type"
                  isMulti={false}
                  value={sourceSourceOptions.find((o) => o.label === sourceSourceSelected)?.label || ""}
                  onChange={(o) => setSourceSourceSelected(o.label)}
                  options={sourceSourceOptions}
                  isLoading={false}
               />
            </div>

            <div className={cx("headingAndBtn")}>
               <span>Source Contacts</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => setContactModal({ isOpen: true, mode: "s" }),
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
                  </div>
                  Add a Contact
               </SHrSosButton>
            </div>

            <div className={cx("sourceContactGroupC")}>
               {!selectedSourceContacts.length ? (
                  <div className={cx("noContact")}>No source's contact found</div>
               ) : (
                  selectedSourceContacts.map((cid, i) => {
                     const contact = contacts.find((c) => c.cid === cid);
                     return (
                        <div className={cx("sourceContactInfoC")} key={i}>
                           <SoSDataPoint
                              type="bold-title"
                              title="Name"
                              value={contact?.name}
                              database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                 qGetContacts.refetch()
                              )("name")}
                           />
                           <SoSDataPoint
                              type="bold-title"
                              title="Phone"
                              value={contact?.phone}
                              database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                 qGetContacts.refetch()
                              )("phone_primary")}
                           />
                           <SoSDataPoint
                              type="bold-title"
                              title="Email"
                              value={contact?.email}
                              database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                 qGetContacts.refetch()
                              )("email")}
                           />
                           <div className={cx("img-icon")} onClick={() => handleRemoveSouceContact(i)}>
                              <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                           </div>
                        </div>
                     );
                  })
               )}
            </div>

            <hr className={cx("divide")} />

            <div className={cx("headingAndBtn")}>
               <span>Property Details</span>
               {/* <SHrSosButton
      type="text"
      buttonProps={{
         className: cx("btn"),
         onClick: () => handleAddProperty(),
      }}
   >
      <div className={cx("img-icon")}>
         <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
      </div>
      Add a Property
   </SHrSosButton> */}
            </div>

            {properties.length ? (
               properties.map((p, i) => (
                  <div className={cx("propertyInforC")} key={i}>
                     <div className={cx("titleAndRemoveBtn")}>
                        <span>Property {i + 1}</span>
                        <div className={cx("img-icon")} onClick={() => handleRemoveProperty(i)}>
                           <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
                        </div>
                     </div>

                     <div className={cx("propertyAddress")}>
                        <SoSInputSelect
                           title="Property Address"
                           isMulti={false}
                           value={p.address}
                           onChange={(o) => handlePropertyAddressChange(i, o.value)}
                           options={propertyAddressesOptions}
                           isLoading={false}
                           onInputChange={(value) => setQ(value)}
                        />
                     </div>

                     <div className={cx("askingPrice")}>
                        <SoSInputTitle
                           title="Asking Price"
                           placeholder="Enter a price"
                           value={p.price}
                           handleValue={(value) => handlePropertyPriceChange(i, value)}
                           type="number"
                        />
                     </div>

                     <div className={cx("propertyContactC")}>
                        <div className={cx("headingAndBtn")}>
                           <span>Contacts</span>
                           <SHrSosButton
                              type="text"
                              buttonProps={{
                                 className: cx("btn"),
                                 onClick: () => handleOpenModalAddContactProperty(i),
                              }}
                           >
                              <div className={cx("img-icon")}>
                                 <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
                              </div>
                              Add a Contact
                           </SHrSosButton>
                        </div>

                        {p.cids.map((cid, i) => {
                           const contact = contacts.find((c) => c.cid === cid);
                           return (
                              <div className={cx("propertyContactInfoC")} key={i}>
                                 <SoSDataPoint
                                    type="bold-title"
                                    title="Name"
                                    value={contact?.name}
                                    database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                       qGetContacts.refetch()
                                    )("name")}
                                 />
                                 <SoSDataPoint
                                    type="bold-title"
                                    title="Phone"
                                    value={contact?.phone}
                                    database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                       qGetContacts.refetch()
                                    )("phone_primary")}
                                 />
                                 <SoSDataPoint
                                    type="bold-title"
                                    title="Email"
                                    value={contact?.email}
                                    database={dbcb("sos_contacts", [{ column: "cid", id: cid }], () =>
                                       qGetContacts.refetch()
                                    )("email")}
                                 />
                                 <div className={cx("img-icon")} onClick={() => handleRemoveContactProperty(i)}>
                                    <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                                 </div>
                              </div>
                           );
                        })}
                     </div>

                     <hr className={cx("divide")} />

                     <div className={cx("headingAndBtn")}>
                        <span>Unit Details</span>
                        <SHrSosButton
                           type="text"
                           buttonProps={{
                              className: cx("btn"),
                              onClick: () => handleAddUnitToProperty(i),
                           }}
                        >
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
                           </div>
                           Add a Unit
                        </SHrSosButton>
                     </div>

                     <div className={cx("unitGroupC")}>
                        {p.units.map((u, j) => (
                           <div className={cx("unitInfoC")} key={j}>
                              <div className={cx("headingAndBtn")}>
                                 <span>Unit {j + 1}</span>
                                 <div className={cx("img-icon")} onClick={() => handleRemoveUnit(j)}>
                                    <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                                 </div>
                              </div>

                              <div className={cx("bedAndBathC")}>
                                 <div className={cx("askingPrice")}>
                                    <SoSInputTitle
                                       title="Bedrooms"
                                       placeholder="Enter number of bedrooms"
                                       inputProps={{
                                          min: 0,
                                       }}
                                       handleValue={(value) => handleBedroomChange(i, j, value)}
                                       value={u.bed}
                                       type={"number"}
                                    />
                                 </div>
                                 <div className={cx("askingPrice")}>
                                    <SoSInputTitle
                                       title="Bathrooms"
                                       placeholder="Enter number of bathrooms"
                                       value={u.bath}
                                       inputProps={{
                                          min: 0,
                                       }}
                                       handleValue={(value) => handleBathroomChange(i, j, value)}
                                       type={"number"}
                                    />
                                 </div>
                              </div>
                           </div>
                        ))}
                     </div>
                     <SHrPopup show={contactModal.isOpen}>
                        <OffersCreateNewContactModal
                           onClose={() => setContactModal({ ...contactModal, isOpen: false })}
                           onSelection={(cid: number) =>
                              contactModal.mode === "p"
                                 ? handleSelectionContactProperty(cid)
                                 : handleSelectionContactSource(cid)
                           }
                        />
                     </SHrPopup>
                  </div>
               ))
            ) : (
               <div className={cx("noProperty")}>No property</div>
            )}
         </div>
         <div className={cx("footerBtn")}>
            <SHrSosButton
               type="text"
               buttonProps={{
                  className: cx("btn"),
                  onClick: () => handleAddProperty(),
               }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/property_viability/add_black.svg")} alt="" />
               </div>
               Add a Property
            </SHrSosButton>

            <SoSConfirmWrapper
               title="Are you sure you want to create properties?"
               content="This action allows to create properties."
               type="default"
               onConfirm={() => handleCreateSource()}
            >
               <div className={cx("btnSave")}>
                  <SHrSosButton
                     buttonProps={{
                        className: cx("btn"),
                        disabled: !sourceSourceSelected || properties.some((p) => !p.address),
                     }}
                     loading={isPending}
                  >
                     Save
                  </SHrSosButton>
               </div>
            </SoSConfirmWrapper>
         </div>
      </>
   );
};

const sourceOptions = [{ value: "1", label: "HomeSmart/OneHome.com" }];

type BulkProps = {
   onClose: () => void;
};
const Bulk = ({ onClose }: BulkProps) => {
   const { toastDispatch } = useToastContext();

   const [sourceSourceSelected, setSourceSourceSelected] = useState<string>("");

   const [typeErrorFile, setTypeErrorFile] = useState("");

   const [dataFromPreBulkCheck, setDataFromPreBulkCheck] = useState<PVi_GetPreBulkCheck_Response | undefined>(
      undefined
   );

   const [dataFromPostBulkCheck, setDataFromPostBulkCheck] = useState<PVi_BulkProperties_Property[]>([]);

   const [uploadedData, setUploadedData] = useState<number>(0);

   const neededColumns = BULK_PROPERTY_HEADERS_BY_PURPOSE["1"];

   const [missingCols, setMissingCols] = useState<string[]>([]);

   const { mutate: mutatePreBulkCheck, isPending: isPendingPreBulkCheck } = useMPVi_PreBulkCheck({
      onSuccess: (data) => {
         setMissingCols([]);
         setDataFromPreBulkCheck(data);

         setDataFromPostBulkCheck((pre) => pre.filter((p) => data.new.includes(`${p.address}, ${p.city}, USA`)));
      },
      onError: (error) => {},
   });

   const { mutate: mutatePropertyBulk, isPending: isPendingPropertyBulk } = useMPVi_PropertyBulk({
      onSuccess: (data) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: `Adding ${data.pids.length} new properties`,
            },
         });
         onClose();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Bulk Properties Action is failed!",
            },
         });
      },
   });

   const handleChange = (fileSelected: File) => {
      if (!fileSelected) return;

      PapaParse.parse<any>(fileSelected, {
         header: true,
         skipEmptyLines: true,
         complete: (results) => {
            const data = results.data;
            const headers = data.length > 0 ? Object.keys(data[0]) : [];
            const missingCols = data.length > 0 ? neededColumns.filter((key) => !headers.includes(key)) : [];

            if (missingCols.length) {
               setMissingCols(missingCols);
               return;
            }

            const dataTransformed = data.map((p) => ({ address: `${p["Address"]}, ${p["City"]}, USA` }));

            mutatePreBulkCheck({ properties: dataTransformed });

            setMissingCols([]);

            setUploadedData(data.length);

            setDataFromPostBulkCheck(
               data.map((p) => ({
                  address: p["Address"],
                  price: parseFloat(p["Price"].replace(/[$,]/g, "")),
                  sqft: parseFloat(p["Square Footage"].replace(/[^\d]/g, "")),
                  units: [{ bed: parseInt(p["Beds"]), bath: parseInt(p["Baths"]) }],
                  city: p["City"],
               }))
            );
         },
      });
   };

   const handlePropertyBulk = () => {
      if (!dataFromPreBulkCheck) return;
      console.log(`dataFromPreBulkCheck`, dataFromPreBulkCheck);
      console.log(`dataFromPostBulkCheck`, dataFromPostBulkCheck);
      console.log(dataFromPostBulkCheck.map((p) => ({ ...p, address: `${p.address}, ${p.city}, USA` })));
      mutatePropertyBulk({
         source_source: sourceSourceSelected,
         properties: dataFromPostBulkCheck.map((p) => ({ ...p, address: `${p.address}, ${p.city}, USA` })),
      });
   };

   return (
      <div className={cx("bulkC")}>
         <div className={cx("sourceType")}>
            <SoSInputSelect
               title="Source Type"
               isMulti={false}
               value={sourceOptions.find((o) => o.label === sourceSourceSelected)?.label || ""}
               onChange={(o) => setSourceSourceSelected(o.label)}
               options={sourceOptions}
               isLoading={false}
            />
         </div>

         {sourceSourceSelected && (
            <>
               <div className={cx("drag-and-drop-file")}>
                  <FileUploader
                     handleChange={handleChange}
                     name="file"
                     types={["CSV"]}
                     classes={cx("file-uploader")}
                     onTypeError={() => setTypeErrorFile("Please select file with CSV extention")}
                  >
                     <div className={cx("drag-and-drop-file-inner")}>
                        <div className={cx("drag-and-drop-icon")}>
                           <div className={cx("img-icon")}>
                              <img src={CF_CDN_URL("/assets/file_upload_black.svg ")} alt="" />
                           </div>
                           <span>Drag & drop or</span>
                        </div>
                        <SHrSosButton type="outlined">Upload CSV</SHrSosButton>
                        <span>Only allow .csv extensions</span>
                     </div>
                  </FileUploader>
               </div>

               {(dataFromPreBulkCheck || isPendingPreBulkCheck) && (
                  <div className={cx("drag-and-drop-file-name")}>
                     <b>{uploadedData}</b> rows detected, found
                     {isPendingPreBulkCheck ? <SHrSpinner /> : <b>{dataFromPreBulkCheck?.new.length}</b>} new and
                     {isPendingPreBulkCheck ? <SHrSpinner /> : <b>{dataFromPreBulkCheck?.existing.length}</b>} existing
                     addresses
                  </div>
               )}

               {(missingCols.length > 0 || typeErrorFile) && (
                  <div className={cx("drag-and-drop-file-status")}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/warning_black.svg")} alt="" />
                     </div>

                     <span>
                        {typeErrorFile
                           ? "Please select file with CSV extention"
                           : `Missing ${missingCols.join(", ")} from CSV, please check!`}
                     </span>
                  </div>
               )}
            </>
         )}

         <div className={cx("btnSave")}>
            <SoSConfirmWrapper
               title="Are you sure you want to create properties?"
               content="This action allows to create properties."
               type="default"
               onConfirm={handlePropertyBulk}
            >
               <SHrSosButton
                  buttonProps={{
                     className: cx("btn"),
                     disabled: !sourceSourceSelected || !dataFromPreBulkCheck || missingCols.length > 0,
                  }}
                  loading={isPendingPropertyBulk}
               >
                  Upload
               </SHrSosButton>
            </SoSConfirmWrapper>
         </div>
      </div>
   );
};

type PViAddNewPropertyModalProps = {
   onClose: () => void;
};
