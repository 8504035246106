import classNames from "classnames/bind";
import styles from "./OffersViableProperties.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useState } from "react";
import { SHrSosButton } from "@simplyhomes/react";
import SoSSlider, { SoSSlider_Item } from "../../../components/SoSSlider/SoSSlider";
import { useQOffers_GetViableProperties } from "../../../hooks/offers/queries/useQOffers_GetViableProperties";
import { useOffersContext } from "../../../contexts/OffersContext";

const cx = classNames.bind(styles);

export const OffersViableProperties = () => {
   const { offersState, offersDispatch } = useOffersContext();
   const { isOpenListViableProperties } = offersState;
   const [isCollapsed, setIsCollapsed] = useState(false);
   const qOfferViableProperties = useQOffers_GetViableProperties();

   const propertiesSliderData: SoSSlider_Item[] =
      qOfferViableProperties.data?.properties.map(({ pid }) => ({
         type: "offer-property-card",
         pid,
      })) || [];

   const propertyLength = qOfferViableProperties.data?.propertyIds.length;

   const handleOpenList = () => {
      offersDispatch({ overwrite: { isOpenListViableProperties: !isOpenListViableProperties } });
      setIsCollapsed(true);
   };

   const handleClickOutSide = () => {
      offersDispatch({ overwrite: { isOpenListViableProperties: false } });
      setIsCollapsed(true);
   };

   return (
      <div className={cx("topC")}>
         <span className={cx("title", "_jr_offersViableProperties")}>
            Viable Properties {`${propertyLength ? `(${propertyLength})` : ""}`}
         </span>
         <SHrSosButton
            type="text"
            buttonProps={{ className: cx("btn", "_jr_offersViablePropertiesExpand"), onClick: handleOpenList }}
         >
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/offer/arrow_downward_black.svg")} alt="" />
            </div>
            Expand List
         </SHrSosButton>
         <div
            className={cx("container", {
               expanded: isOpenListViableProperties,
               collapsed: isCollapsed && !isOpenListViableProperties,
            })}
         >
            <div className={cx("sliderPropertyListC")}>
               <div className={cx("titleC")}>
                  <span className={cx("title")}>
                     Viable Properties {`${propertyLength ? `(${propertyLength})` : ""}`}
                  </span>
                  <SHrSosButton type="text" buttonProps={{ className: cx("btn"), onClick: handleOpenList }}>
                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/offer/arrow_upward_black.svg")} alt="" />
                     </div>
                     Hide List
                  </SHrSosButton>
               </div>
               <SoSSlider items={propertiesSliderData}></SoSSlider>
            </div>
            <div className={cx(`background`)} onClick={handleClickOutSide}></div>
         </div>
      </div>
   );
};
