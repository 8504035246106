import { useLocation, useNavigate, createSearchParams } from "react-router-dom";

export const useSoSNavigate = (p?: useSoSNavigateProps) => {
   const { persistQueryParams = true } = p || {};
   const navigate = useNavigate();
   const { search } = useLocation();
   const nav = (pathname: string | number) =>
      navigate({
         pathname: pathname.toString(),
         search: persistQueryParams ? createSearchParams(search).toString() : undefined,
      });
   return { nav };
};
type useSoSNavigateProps = {
   persistQueryParams?: boolean;
};
