import { SHrPopup, SHrSosButton } from "@simplyhomes/react";
import { useState } from "react";
import styles from "./SoSConfirmWrapper.module.scss";
export const SoSConfirmWrapper = (p: TSoSConfirmWrapperProps) => {
   const { children, onCancel = () => {}, onConfirm = () => {}, setShow: externalSetShow, show: externalShow } = p;
   const [internalShow, setInternalShow] = useState(false);

   const isExternalShow = externalShow !== undefined && externalSetShow !== undefined;
   const show = isExternalShow ? externalShow : internalShow;
   const setShow = (b: boolean, confirm?: boolean) => {
      if (isExternalShow) externalSetShow(b);
      else setInternalShow(b);
      if (confirm != null) {
         if (confirm) onConfirm();
         else onCancel();
      }
   };

   return (
      <SHrPopup show={show} handleShow={setShow} triggerElement={children}>
         {"customConfirm" in p ? (
            p.customConfirm
         ) : p.type === "default" ? (
            <SoSConfirmWrapperDefault
               Confirm={(confirm) => setShow(false, confirm)}
               title={p.title}
               content={p.content}
            />
         ) : null}
      </SHrPopup>
   );
};

type TSoSConfirmWrapperProps = {
   children: React.ReactNode;
   onConfirm?: () => void;
   onCancel?: () => void;
} & ({ show: boolean; setShow: (show: boolean) => void } | { show?: undefined; setShow?: undefined }) &
   (({ type: "default" } & SoSConfirmWrapperDefaultProps) | { customConfirm: React.ReactNode });

type ChildrenBaseProps = { Confirm: (b: boolean) => void; yes?: string; no?: string };

type SoSConfirmWrapperDefaultProps = { title?: string; content?: string | React.ReactNode };
const SoSConfirmWrapperDefault = (p: SoSConfirmWrapperDefaultProps & ChildrenBaseProps) => {
   const { Confirm, content, title, no, yes } = p;

   return (
      <div className={`${styles.default}`}>
         <div className={`${styles.defaultTopC}`}>
            <b>{title || "Are you sure you want to make a change?"}</b>
         </div>
         <div className={`${styles.defaultBodyC}`}>
            {content ||
               "This action cannot be undone. You will need to contact the administrator to restore the data if needed."}
         </div>
         <div className={`${styles.defaultBotC}`}>
            <div className={`${styles.cancleBtn}`}>
               <SHrSosButton type="outlined" buttonProps={{ onClick: () => Confirm(false) }}>
                  {no || "Cancel"}
               </SHrSosButton>
            </div>

            <SHrSosButton buttonProps={{ onClick: () => Confirm(true) }}>{yes || "Confirm"}</SHrSosButton>
         </div>
      </div>
   );
};
