import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useTransactionsContext } from "../../../../contexts/TransactionsContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useQTrans_Transaction } from "../../queries/useQTrans_Transaction";

export const useMTrans_DeleteInsurancePolicy = () => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const { oid, pidSelected } = useTransactionsContext().transactionsState;

   const qGetTransaction = useQTrans_Transaction();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Delete Insurance Policy is successful!",
            },
         });
         qGetTransaction.refetch();
      },
      onError: (error) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Delete Insurance Policy is failed!",
            },
         });
      },

      mutationFn: ({ ipoid }: Transactions_DeleteInsurancePolicy_Params) =>
         sosAxios
            .delete(`/transactions/${oid}/properties/${pidSelected}/insurance-policies/${ipoid}`)
            .then<Transactions_DeleteInsurancePolicy_Response>(({ data }) => data),
   });
};
export class Transactions_DeleteInsurancePolicy_Response {
   message!: string;
}

export type Transactions_DeleteInsurancePolicy_Params = {
   ipoid: number;
};
