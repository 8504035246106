import { useEffect } from "react";
import { useWebSocketContext } from "../../contexts/WebSocketContext";

export const useWebSocket_CatchEvent = <Data>(e: event, callback: (data: Data) => void) => {
   const { socket } = useWebSocketContext();
   const Event = e.room + ("id" in e ? e.id : "");
   useEffect(() => {
      if (!socket) return;
      socket.on(Event, callback);
      return () => {
         socket.off(Event);
      };
   }, [callback, Event, socket]);
};
type event =
   | {
        room: "refresh:Offers:Leads" | "refresh:PVi:Properties";
     }
   | {
        room:
           | "refresh:Convo:Threads:"
           | "refresh:Convo:Comments:"
           | "refresh:Notifications:"
           | "refresh:PVi:Property:"
           | "refresh:Offers:Lead:";
        id: string | number | undefined | null;
     };
