import "react-calendar-timeline/lib/Timeline.css";
import "./App.scss";
import { PrivateAppRoutes, PublicAppRoutes } from "./routes";
import { useAppContext } from "./contexts/AppContext";
import { useGoogleMapsApiLoader } from "./hooks/google-maps-api/useGoogleMapsApiLoader";
import { Navigate, Route, Routes, createSearchParams } from "react-router-dom";
import { constants_paths } from "./constants/path";

const App = () => {
   const { credential } = useAppContext().appState;
   const { isLoaded } = useGoogleMapsApiLoader();
   if (!isLoaded) return null;
   const curPath = window.location.pathname;
   const redirectSignInPath =
      curPath !== constants_paths.SIGN_IN
         ? constants_paths.SIGN_IN + "?" + createSearchParams({ redirect: curPath }).toString()
         : constants_paths.SIGN_IN;
   return (
      <div className="AppC">
         {credential ? (
            <PrivateAppRoutes />
         ) : (
            <Routes>
               <Route path="*" element={<Navigate to={redirectSignInPath} replace />} />
            </Routes>
         )}
         <PublicAppRoutes />
      </div>
   );
};

export default App;
