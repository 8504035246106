import DataTable, { TableColumn } from "react-data-table-component";
import { useContactsData } from "../../../../../hooks/contact/customhook/useContactsData";
import { TSoS_Offers_Contact } from "../../../../../hooks/offers/queries/leads/useQOffers_GetLeadSourceProperties";
import { SoSDataPoint } from "../../../../../components/SoSDataPoint/SoSDataPoint";
import { database_helpers_createPayloadHelper as dbcb } from "../../../../../hooks/database/mutations/utils";
import { SHrSpinner } from "@simplyhomes/react";
import classNames from "classnames/bind";
import styles from "./ContactTable.module.scss";
import { useSoSOptions_SoS_ContactType } from "../../../../../hooks/queries/options/useQSoS_Options";
import { useMemo } from "react";
import { useOffersContext } from "../../../../../contexts/OffersContext";

export type TContactInfo = {
   contact: TSoS_Offers_Contact;
   type?: string;
   object: string;
   relationship: string;
};

const cx = classNames.bind(styles);

export const ContactTable = () => {
   const { pid } = useOffersContext().offersState;

   const { qGetContacts, qOfferGetPropertyByIdInLead } = useContactsData();

   const qContactTypes = useSoSOptions_SoS_ContactType();

   const contactTypesOptions = useMemo(() => qContactTypes.data?.options || [], [qContactTypes.data?.options]);

   const property = qOfferGetPropertyByIdInLead.data?.property;

   const source = qOfferGetPropertyByIdInLead.data?.source;

   const contactArray: TContactInfo[] = [
      ...(source?.contacts ?? [])
         .sort((a, b) => a.cid - b.cid)
         .map((contact: TSoS_Offers_Contact) => ({
            contact,
            object: "Source",
            relationship: "",
         })),
      ...(property?.contacts
         ?.sort((a, b) => a.cid - b.cid)
         .map((contact: TSoS_Offers_Contact) => ({
            contact,
            object: "Property",
            type: contactTypesOptions.find((o) => o.value === contact.type)?.label,
            relationship: property.address_one_line,
         })) ?? []),
   ];

   const allContactsColumn: TableColumn<TContactInfo>[] = [
      {
         name: "Contact Name",
         cell: (contactInfo) => (
            <SoSDataPoint
               type="bold-value-table"
               value={contactInfo.contact.name}
               database={dbcb("sos_contacts", [{ column: "cid", id: contactInfo.contact.cid }], () => {
                  qOfferGetPropertyByIdInLead.refetch();
                  qGetContacts.refetch();
               })("name")}
            />
         ),
      },
      {
         name: "Email",
         cell: (contactInfo) => (
            <SoSDataPoint
               type="only-value-table"
               value={contactInfo.contact.email}
               database={dbcb("sos_contacts", [{ column: "cid", id: contactInfo.contact.cid }], () => {
                  qOfferGetPropertyByIdInLead.refetch();
                  qGetContacts.refetch();
               })("email")}
            />
         ),
      },
      {
         name: "Phone",
         cell: (contactInfo) => (
            <SoSDataPoint
               type="only-value-table"
               value={contactInfo.contact.phone_primary}
               database={dbcb("sos_contacts", [{ column: "cid", id: contactInfo.contact.cid }], () => {
                  qOfferGetPropertyByIdInLead.refetch();
                  qGetContacts.refetch();
               })("phone_primary")}
            />
         ),
      },
      {
         name: "Type",
         cell: (contactInfo) =>
            contactInfo.object === "Property" ? (
               <div className={cx("input")}>
                  <SoSDataPoint
                     type="only-value"
                     value={contactInfo.type}
                     options={contactTypesOptions}
                     database={dbcb(
                        "sos_rel_contacts_properties",
                        [
                           { column: "cid", id: contactInfo.contact.cid },
                           { column: "pid", id: pid },
                        ],
                        () => {
                           qGetContacts.refetch();
                           qOfferGetPropertyByIdInLead.refetch();
                        }
                     )("type")}
                  />
               </div>
            ) : null,
      },
      { name: "Object", selector: (contactInfo) => contactInfo.object },
      { name: "Relationship", selector: (contactInfo) => contactInfo.relationship },
   ];

   if (qOfferGetPropertyByIdInLead.isLoading) {
      return (
         <div className={cx("loading")}>
            <SHrSpinner /> <span>Loading Contact...</span>
         </div>
      );
   }

   return (
      <DataTable
         columns={allContactsColumn}
         data={contactArray}
         customStyles={{
            headRow: {
               style: {
                  backgroundColor: "#E2EBF3",
               },
            },
         }}
      />
   );
};
