import DataTable from "react-data-table-component";
import classNames from "classnames/bind";
import { allPropertiesColumn } from "./data-table-column";
import styles from "./PViListView.module.scss";
import { usePViContext } from "../../../contexts/PViContext";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import {
   PVi_GetProperties_Property,
   useQPVi_Properties,
} from "../../../hooks/property-viability/queries/useQPVi_Properties";
import { useSoSNavigate } from "../../../hooks/navigate/useSoSNavigate";

const cx = classNames.bind(styles);

export const PViListView = () => {
   const { nav } = useSoSNavigate();

   const { pviOverwrite } = usePViContext();

   const qPVi_Properties = useQPVi_Properties();
   const { properties = [] } = qPVi_Properties;

   const handleShowDetailInformationClick = (property: PVi_GetProperties_Property) => {
      nav(property.pid);
      pviOverwrite({ showDetailsModal: true });
   };

   if (qPVi_Properties.isLoading) {
      return (
         <div className={cx("loading")}>
            <SHrSpinner /> <span>Loading Properties...</span>
         </div>
      );
   }

   return (
      <div className={cx("tableC")}>
         <DataTable columns={allPropertiesColumn} data={properties} onRowClicked={handleShowDetailInformationClick} />

         <div className={cx("footer")}>
            <SHrSosButton
               buttonProps={{
                  onClick: () => qPVi_Properties.fetchNextPage(),
                  className: cx("load-more", {
                     hidden: !qPVi_Properties.hasNextPage || qPVi_Properties.isFetchingNextPage,
                  }),
               }}
            >
               Load More
            </SHrSosButton>
         </div>
      </div>
   );
};
