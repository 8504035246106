import classNames from "classnames/bind";
import styles from "./SoSUploaderContainer.module.scss";
import { SHrSpinner } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSImageViewer } from "../../SoSImageViewer/SoSImageViewer";

import { SoSUploaderWrapper } from "../../SoSUploaderWrapper/SoSUploaderWrapper";

const cx = classNames.bind(styles);

export const SoSUploaderContainer = ({ type, values, onUpload, onRemove, children }: SoSUploaderContainerProps) => {
   return type === "files" ? (
      <div className={cx("container")}>
         <SoSUploaderWrapper type="files" onUpload={onUpload}>
            {children}
         </SoSUploaderWrapper>

         <div className={cx("linkC")}>
            {(values as SoSUploaderContainerFileType[])?.map((file, index) => (
               <div key={index} className={cx("linkUploadC")}>
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/property_validator/insert_drive_file_black.svg")} alt="" />
                  </div>
                  <span>{file.file_name}</span>
                  <div className={cx("linkIconsC")}>
                     {file.uploading && <SHrSpinner />}

                     <div className={cx("img-icon")}>
                        <img src={CF_CDN_URL("/assets/property_validator/download_black.svg")} alt="" />
                     </div>
                     <div
                        className={cx("img-icon", "clear")}
                        onClick={() => {
                           if (onRemove) onRemove(file.fuid);
                        }}
                     >
                        <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   ) : (
      <div className={cx("container")}>
         <div className={cx("linkC", "linkC-image")}>
            <SoSImageViewer
               images={(values as SoSUploaderContainerImageType[])?.filter((i) => !i.uploading).map((i) => i.url)}
            />
            {(values as SoSUploaderContainerImageType[])
               ?.filter((i) => i.uploading)
               .map((image, i) => (
                  <div className={cx("uploading-image")}>
                     <img key={i} src={image.url} alt="" className={cx("images")} />
                     <div className={cx("spinner")}>
                        <SHrSpinner />
                     </div>
                  </div>
               ))}

            <SoSUploaderWrapper type="images" onUpload={onUpload}>
               {children}
            </SoSUploaderWrapper>
         </div>
      </div>
   );
};

type SoSUploaderContainerBaseProps = {
   values?: SoSUploaderContainerFileType[] | SoSUploaderContainerImageType[];
   children?: React.ReactNode;
   onUpload: (files: FileList) => void;
};

type SoSUploaderContainerFileProps = SoSUploaderContainerBaseProps & {
   type: "files";
   values?: SoSUploaderContainerFileType[];
   onRemove?: (fuid: number) => void;
};

type SoSUploaderContainerImageProps = SoSUploaderContainerBaseProps & {
   type: "images";
   values?: SoSUploaderContainerImageType[];
   onRemove?: (iuid: string) => void;
};

type SoSUploaderContainerProps = SoSUploaderContainerFileProps | SoSUploaderContainerImageProps;

export type SoSUploaderContainerFileType = {
   fuid: number;
   file_name: string;
   uploading?: boolean;
};

export type SoSUploaderContainerImageType = {
   iuid: string;
   url: string;
   uploading?: boolean;
};
