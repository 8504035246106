import { SHu_Proj4 } from "@simplyhomes/utils";

const deltaX = 223.93660623580217;
const deltaY = 149.28997827600688;
export const useFEMA_FloodMap = ({ lat, lng }: useQFEMA_FloodMap_Params) => {
   const [x, y] = lng && lat ? SHu_Proj4("EPSG:4326", "EPSG:3857", [lng, lat]) : [0, 0];
   const bbox = [x - deltaX, y - deltaY, x + deltaX, y + deltaY].join(",");
   const baseParams = { f: "image", imageSR: "102100", bboxSR: "102100", size: "1500,1000", bbox };
   const mapSearchParams = new URLSearchParams(baseParams).toString();
   const mapSrc =
      "https://imagery.nationalmap.gov/arcgis/rest/services/USGSNAIPPlus/ImageServer/exportImage?" + mapSearchParams;
   const overlaySearchParams = new URLSearchParams({
      ...baseParams,
      layers: "show:0,2,4,5,7,8,9,12,13,14,15,18,19,20,21",
      format: "png32",
      transparent: "true",
   }).toString();
   //    const overlaySrc = "https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/export?" + overlaySearchParams;
   const overlaySrc =
      "https://hazards.fema.gov/arcgis/rest/services/FIRMette/NFHLREST_FIRMette/MapServer/export?" +
      overlaySearchParams;
   return { mapSrc, overlaySrc, arcGisCoords: [x, y] };
};

type useQFEMA_FloodMap_Params = { lat?: number; lng?: number };
