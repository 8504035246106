import { constants_paths } from "./path";
export type TAppCategory =
   | "all"
   | "engineer"
   | "leads"
   | "underwriting"
   | "transaction"
   | "renovation"
   | "asset-management";

export type TApp = {
   category?: TAppCategory;
   name: string;
   icon: string;
   description?: string;
   id: string;
   path: string;
};

export const apps: TApp[] = [
   {
      id: "app_pvi",
      icon: "add_home_work_black.svg",
      category: "leads",
      name: "Property Validator",
      description: "Tool to validate property details for accuracy and completeness.",
      path: constants_paths["PROPERTY_VIABILITY"],
   },
   {
      id: "app_offers",
      icon: "contact_mail_black.svg",
      category: "leads",
      name: "Offers",
      description: "Manage property offers, create, review, and handle transactions.",
      path: constants_paths["OFFERS"],
   },
   {
      id: "app_qeditor",
      icon: "analytic_black.svg",
      category: "engineer",
      name: "Question Editor",
      description: "Edit questions for the Simply Widget",
      path: constants_paths["APPS_QEDITOR"],
   },
   {
      id: "app_transaction",
      icon: "payments_black.svg",
      category: "transaction",
      name: "Transaction",
      description:
         "Enables users to monitor the entire transaction process, including EMD, inspections, title work, financing, and the final closing walkthrough. It ensures that every step is tracked and completed on time for smooth property acquisitions.",
      path: constants_paths["APPS_TRANSACTION"],
   },
   {
      id: "app_csv_upload",
      icon: "cloud_upload.svg",
      category: "engineer",
      name: "CSV Upload",
      description: "Upload and manage CSV files for engineering data.",
      path: constants_paths["APPS_CSV_UPLOAD"],
   },
   {
      icon: "location_black.svg",
      description: "Simply's Map",
      name: "Simply Map",
      category: "all",
      id: "app_map",
      path: constants_paths["APPS_MAP"],
   },
];
