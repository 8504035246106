import { useMutation } from "@tanstack/react-query";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useToastContext } from "../../../../contexts/ToastContext";

export const useMPVi_PreBulkCheck = (
   { onError, onSuccess }: useMPVi_PreBulkCheckProps = { onError: () => {}, onSuccess: () => {} }
) => {
   const { sosAxios } = useSosAxios();

   const { toastDispatch } = useToastContext();

   const mutation = useMutation({
      onSuccess: (data) => {
         toastDispatch({ type: "ADD_TOAST", payload: { type: "success", message: "Pre Bulk Check is successful!" } });
         onSuccess(data);
      },
      onError: (error) => {
         toastDispatch({ type: "ADD_TOAST", payload: { type: "error", message: "Pre Bulk Check is failed!" } });
         onError(error);
      },
      mutationFn: (p: PVi_GetPreBulkCheck_Params) =>
         sosAxios
            .post(`/property-viability/properties/pre-bulk-check`, { ...p })
            .then(({ data }: { data: PVi_GetPreBulkCheck_Response }) => data),
   });
   return { ...mutation };
};

class PVi_GetPreBulkCheck_Body_Property {
   address!: string;
}

type useMPVi_PreBulkCheckProps = {
   onSuccess: (data: PVi_GetPreBulkCheck_Response) => void;
   onError: (error: any) => void;
};

export class PVi_GetPreBulkCheck_Response {
   existing!: string[];
   new!: string[];
}

export type PVi_GetPreBulkCheck_Params = { properties: PVi_GetPreBulkCheck_Body_Property[] };
