import classNames from "classnames/bind";
import { useEffect, useMemo, useState } from "react";
import { SHrPopup, SHrSosButton, SHrSosTabsBar } from "@simplyhomes/react";

import styles from "./PropertyViabilityPage.module.scss";
import SoSFilter from "../../components/common/SoSFilter/SoSFilter";

import { CF_CDN_URL } from "../../utils/CF_CDN_URL";
import { useQPVi_FilterOptions } from "../../hooks/property-viability/queries/useQPVi_FilterOptions";
import { SoSInputSelect } from "../../components/common/SoSInputSelect/SoSInputSelect";
import { SoSInputTitle } from "../../components/common/soSInputTitle/SoSInputTitle";
import { usePViQueryParams } from "../../hooks/property-viability/hooks/usePViQueryParams";
import { PViAskingPriceFilter } from "./PViAskingPriceFilter/PViAskingPriceFilter";
import { PViGridView } from "./PViGridView/PViGridView";
import { PViListView } from "./PViListView/PViListView";
import { PViMapView } from "./PViMapView/PViMapView";
import { PViPropertyDetailsModal } from "./PViPropertyDetailsModal/PViPropertyDetailsModal";
import { useQPVi_Properties } from "../../hooks/property-viability/queries/useQPVi_Properties";
import {
   PVi_GetPropertyStatuses_PropertyStatus,
   useQPVi_PropertyStatuses,
} from "../../hooks/property-viability/queries/useQPVi_PropertyStatuses";
import { ESoS_PropertyStatus } from "@simplyhomes/utils";
import useDebounce from "../../hooks/react/useDebounce";
import ReactJoyride, { Step } from "react-joyride";
import { PViAddNewPropertyModal } from "./PViAddNewPropertyModal/PViAddNewPropertyModal";
import { useMPVi_QuickPickProperty } from "../../hooks/property-viability/mutates/properties/useMPVi_QuickPickProperty";
import { SoSToolbox } from "../../components/SoSToolBox/SoSToolBox";
import { usePViContext } from "../../contexts/PViContext";
import { useParams } from "react-router-dom";
import { PViZipCodesMapModal } from "./PViZipCodesMapModal/PViZipCodesMapModal";

const cx = classNames.bind(styles);

enum EViews {
   GridView = "Grid View",
   MapView = "Map View",
   ListView = "List View",
}
const viewIcons: Record<string, string | undefined> = {
   "Grid View": CF_CDN_URL("/assets/property_viability/grid_view_primary.svg"),
   "Map View": CF_CDN_URL("/assets/property_viability/map_view_primary.svg"),
   "List View": CF_CDN_URL("/assets/property_viability/list_view_primary.svg"),
};

const joyrideSteps: Step[] = [
   {
      target: "._jr_pviTopLeftFilter",
      content: "This is the filter section. You can filter properties by these options.",
      disableBeacon: true,
   },
   {
      target: "._jr_pviTabs",
      content: "This is the status tab. You can filter properties by status.",
   },
   {
      target: "._jr_pviProperties",
      content: "This is the properties section. You can view properties based on your selected properties.",
   },
   {
      target: "._jr_pviViews",
      content: "This is the view section. You can switch between grid, list, and map view.",
   },
   {
      target: "._jr_pviQuickPick",
      content: "This is the quick pick button. You can start quick pick queue from here.",
   },
   {
      target: "._jr_pviAddNewProperty",
      content: "This is the create new property button. You can create a new property from here.",
   },
];

export const PropertyViabilityPage = () => {
   const { view } = usePViQueryParams();

   const { pviState, pviOverwrite } = usePViContext();

   const { pid } = useParams();

   useEffect(() => {
      if (!!pid && pviState.pid !== pid) pviOverwrite({ pid });
   }, [pid, pviState.pid, pviOverwrite]);

   const { isRunStepJoyRideInPropertyBrowser } = pviState;

   return (
      <div className={cx(`contentC`)}>
         <div className={cx("toolBoxWrapper")}>
            <SoSToolbox
               items={[
                  {
                     title: "Guide For Property",
                     icon: "info",
                     onClick: () => pviOverwrite({ isRunStepJoyRideInPropertyBrowser: true }),
                  },
               ]}
            />
         </div>
         <ReactJoyride
            callback={(data) => {
               if (data.action === "skip" || data.status === "finished")
                  pviOverwrite({ isRunStepJoyRideInPropertyBrowser: false });
            }}
            continuous
            run={isRunStepJoyRideInPropertyBrowser}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={joyrideSteps}
         />
         <PViPropertyDetailsModal />
         <PViTop />
         <div className={cx("body", "_jr_pviProperties")}>
            {view === EViews.GridView ? (
               <PViGridView />
            ) : view === EViews.ListView ? (
               <PViListView />
            ) : view === EViews.MapView ? (
               <PViMapView />
            ) : null}
         </div>
      </div>
   );
};

const PViTop = () => {
   const qpPVi = usePViQueryParams();

   const { status, propertyTypes, zips, states, markets, sources, q, filters, view, hasLead } = qpPVi;

   const { setStatus, setPropertyTypes, setZips, setStates, setMarkets, setSources, setQ, setView, setHasLead } = qpPVi;

   const qPVi_Properties = useQPVi_Properties();

   const qPVi_PropertyStatuses = useQPVi_PropertyStatuses();

   const mPVi_QuickPickProperty = useMPVi_QuickPickProperty();

   const [preTabs, setPreTabs] = useState<PVi_GetPropertyStatuses_PropertyStatus[]>([]);

   const tabOptions = useMemo(() => {
      const statuses = qPVi_PropertyStatuses.data?.statuses || preTabs || [];
      return (
         statuses.map(({ status, count }) => ({
            label: `${status} (${count})`,
            value: status,
         })) || []
      );
   }, [preTabs, qPVi_PropertyStatuses.data?.statuses]);

   const filterOptions = useQPVi_FilterOptions().data;

   const [search, setSearch] = useState(q);

   const [isOpenZipCodesModal, setIsOpenZipCodesModal] = useState(false);

   const [isOpenAddNewPropertyModal, setIsOpenAddNewPropertyModal] = useState(false);

   const searchDebounced = useDebounce(search, 500);

   const newPropLength = qPVi_PropertyStatuses.data?.statusMap?.New || 0;

   const isFilter = filters.markets.length > 0 || filters.propertyTypes.length > 0 ? " With Filters" : "";

   const handleChangeTab = (v: string) => {
      qPVi_PropertyStatuses.refetch();
      qPVi_Properties.refetch();
      setStatus(v);
   };

   useEffect(() => {
      setQ(searchDebounced);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchDebounced]);

   useEffect(() => {
      if (!qPVi_PropertyStatuses.data) return;
      setPreTabs(qPVi_PropertyStatuses.data?.statuses as PVi_GetPropertyStatuses_PropertyStatus[]);
   }, [qPVi_PropertyStatuses.data]);

   return (
      <div className={cx("top")}>
         <div className={cx("topFilter")}>
            <section className={cx("topLeft", "_jr_pviTopLeftFilter")}>
               <div className={cx("input_search")}>
                  <SoSInputTitle
                     value={search}
                     type="text"
                     handleValue={setSearch}
                     icon="search"
                     placeholder="Search the address"
                  />
               </div>

               <div className={cx("divider-container")}>
                  <hr className={cx("divider")} />
               </div>

               <div className={cx("filterList")}>
                  <SoSFilter
                     title="States"
                     type="select"
                     isMulti={true}
                     value={states}
                     onChange={(vs) => setStates(vs.map((o) => o.value))}
                     options={filterOptions?.states || []}
                     className={cx("filter-dropdown")}
                  />

                  <SoSFilter
                     title="Market"
                     type="select"
                     isMulti={true}
                     value={markets}
                     onChange={(vs) => setMarkets(vs.map((o) => o.value))}
                     options={filterOptions?.markets || []}
                     className={cx("filter-dropdown")}
                  />

                  <SoSFilter
                     title="ZIP"
                     type="select"
                     isMulti={true}
                     value={zips}
                     onChange={(vs) => setZips(vs.map((o) => o.value))}
                     options={filterOptions?.zips || []}
                     className={cx("filter-dropdown")}
                  />
                  <SoSFilter
                     title="Property Type"
                     type="select"
                     isMulti={true}
                     value={propertyTypes}
                     onChange={(vs) => setPropertyTypes(vs.map((o) => o.value))}
                     options={filterOptions?.propertyTypes || []}
                     className={cx("filter-dropdown")}
                  />

                  <SoSFilter
                     title="Source"
                     type="select"
                     isMulti={true}
                     value={sources}
                     onChange={(vs) => setSources(vs.map((o) => o.value))}
                     options={filterOptions?.sources || []}
                     className={cx("filter-dropdown")}
                  />

                  <PViAskingPriceFilter />

                  <SoSFilter
                     title="Lead"
                     type="select"
                     isMulti={false}
                     value={hasLead}
                     onChange={(v) => {
                        setHasLead(v.value === hasLead ? "" : v.value);
                     }}
                     options={hasLeadOption}
                     className={cx("filter-dropdown")}
                     isBoldTitle={!!hasLead}
                  />
               </div>
            </section>
            <section className={cx("topRight")}>
               <div className={cx("view", "_jr_pviViews")}>
                  <div className={cx("img")}>{viewIcons[view] && <img src={viewIcons[view]} alt="" />}</div>
                  <SoSInputSelect
                     options={Object.values(EViews)}
                     value={view}
                     onChange={(op) => setView(op.value)}
                     isMulti={false}
                     className={cx("filter-dropdown")}
                  />
               </div>

               {newPropLength > 0 && status === ESoS_PropertyStatus.NEW && (
                  <div className={cx("quickPickBtn", "_jr_pviQuickPick")}>
                     <SHrSosButton
                        loading={mPVi_QuickPickProperty.isPending}
                        buttonProps={{ onClick: () => mPVi_QuickPickProperty.mutate() }}
                     >
                        Start Quick Pick
                        {isFilter} ({newPropLength})
                     </SHrSosButton>
                  </div>
               )}
            </section>
         </div>
         <div className={cx("topTab", "_jr_pviTabs")}>
            <SHrSosTabsBar tabs={tabOptions} value={status} onChange={handleChangeTab} />

            <div className={cx("addnewPropertyBtn", "_jr_pviAddNewProperty")}>
               <SHrSosButton
                  type="text"
                  buttonProps={{ className: cx("btn", "see"), onClick: () => setIsOpenZipCodesModal(true) }}
               >
                  See Zip Codes
               </SHrSosButton>
               <SHrSosButton
                  type="white"
                  buttonProps={{ className: cx("btn"), onClick: () => setIsOpenAddNewPropertyModal(true) }}
               >
                  Add New Property
               </SHrSosButton>
            </div>
         </div>

         <SHrPopup show={isOpenAddNewPropertyModal}>
            <PViAddNewPropertyModal onClose={() => setIsOpenAddNewPropertyModal(false)} />
         </SHrPopup>

         <SHrPopup show={isOpenZipCodesModal}>
            <PViZipCodesMapModal onClose={() => setIsOpenZipCodesModal(false)} />
         </SHrPopup>
      </div>
   );
};

// export const PropertyViabilityPage = () => {
//    return (
//       <PViProvider>
//          <PVi />
//       </PViProvider>
//    );
// };
const hasLeadOption = [
   { value: "true", label: "Has Lead" },
   { value: "false", label: "No Lead" },
];
