import { useQuery } from "@tanstack/react-query";

import { useSosAxios } from "../../axios/useSosAxios";
import { usePViQueryParams } from "../hooks/usePViQueryParams";

export const useQPVi_FilterOptions = () => {
   const { sosAxios } = useSosAxios();
   const { status } = usePViQueryParams();
   const query = useQuery({
      queryKey: [`useQPVi_FilterOptions`, status],
      queryFn: () =>
         sosAxios
            .get(`/property-viability/filters-options`, { params: { sourceStatus: status } })
            .then(({ data }: { data: PVi_FiltersOptions_Response_Dto }) => data),
   });

   return query;
};

export class PVi_FiltersOptions_Response_Dto {
   markets!: string[];
   zips!: string[];
   propertyTypes!: string[];
   sources!: string[];
   numberPropertyBySourcePrice!: PVi_FiltersOptions_NumberPropertyBySourcePrice[];
   states!: string[];
}
export class PVi_FiltersOptions_NumberPropertyBySourcePrice {
   sourcePrice!: number;
   numberProperty!: number;
}
