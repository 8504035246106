import classNames from "classnames/bind";
import styles from "./PViZipCodesMapModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { GoogleMapWrapper } from "../../../components/GoogleMapWrapper/GoogleMapWrapper";
import { useEffect, useMemo, useRef, useState } from "react";
import { SoSInputTitle } from "../../../components/common/soSInputTitle/SoSInputTitle";
import { SoSInputSelect } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { PVi_GetZipcodes_Zipcode, usePVi_ZipCodes } from "../../../hooks/property-viability/queries/usePVi_ZipCodes";
import { PViZipCodeCard } from "./PViZipCodeCard/PViZipCodeCard";
import { SHrSpinner } from "@simplyhomes/react";
import useDebounce from "../../../hooks/react/useDebounce";
// import InfiniteScroll from "react-infinite-scroller";

const cx = classNames.bind(styles);

type LatLng = {
   lat: number;
   lng: number;
};

type MapBounds = {
   northeast: LatLng;
   southwest: LatLng;
   northwest: LatLng;
   southeast: LatLng;
};
export const PViZipCodesMapModal = ({ onClose }: PViZipCodesMapModalProps) => {
   const ref = useRef<HTMLDivElement>(null);

   const [center, setCenter] = useState({ lat: 40.4406248, lng: -79.9958864 });

   const [mapBounds, setMapBounds] = useState<MapBounds>();

   const [zoom, setZoom] = useState(12);

   const [selectedZip, setSelectedZip] = useState("");

   const [search, setSearch] = useState("");

   // const [hasMore, setHasMore] = useState(true);

   // const [page, setPage] = useState(0);

   const qZipCodes = usePVi_ZipCodes();

   useEffect(() => {
      if (ref.current) {
         const map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
         });

         map.addListener("bounds_changed", () => {
            const bounds = map.getBounds();
            if (bounds) {
               const ne = bounds.getNorthEast();
               const sw = bounds.getSouthWest();

               const nw = { lat: ne.lat(), lng: sw.lng() };
               const se = { lat: sw.lat(), lng: ne.lng() };

               setMapBounds({
                  northeast: { lat: ne.lat(), lng: ne.lng() },
                  southwest: { lat: sw.lat(), lng: sw.lng() },
                  northwest: nw,
                  southeast: se,
               });
            }
         });
      }
   }, [center, zoom]);

   const options = [
      { label: `Blacklisted (${qZipCodes.data?.filter.blacklisted_count})`, value: "blacklisted" },
      { label: `All (${qZipCodes.data?.filter.all})`, value: "all" },
   ];

   const debouncedSearch = useDebounce(search, 500);

   const [selectedOption, setSelectedOption] = useState<string>(options[0].value);

   const zipcodes = useMemo(() => {
      const zips = qZipCodes.data?.zipcodes || [];
      const filtered =
         zips.filter((zip) => {
            const matchesSearch = zip.zid.toLowerCase().startsWith(debouncedSearch.toLowerCase());
            const matchesFilter = selectedOption === "all" || (selectedOption === "blacklisted" && zip.blacklisted);
            return matchesSearch && matchesFilter;
         }) || [];
      if (selectedOption === "all") return filtered.splice(0, 200);
      return filtered;
   }, [debouncedSearch, qZipCodes.data?.zipcodes, selectedOption]);

   // const zipcodes = useMemo(() => {
   //    const zips = qZipCodes.data?.zipcodes || [];
   //    const filtered =
   //       zips.filter((zip) => {
   //          const matchesSearch = zip.zid.toLowerCase().startsWith(debouncedSearch.toLowerCase());
   //          const matchesFilter = selectedOption === "all" || (selectedOption === "blacklisted" && zip.blacklisted);
   //          return matchesSearch && matchesFilter;
   //       }) || [];
   //    return filtered;
   // }, [debouncedSearch, qZipCodes.data?.zipcodes, selectedOption]);

   // const loadMore = () => {
   //    if (page * 20 >= zipcodes.length) {
   //       setHasMore(false);
   //       return;
   //    }
   //    setPage((prevPage) => prevPage + 1);
   // };

   const handleClickToZip = ({ x, y, zid }: Pick<PVi_GetZipcodes_Zipcode, "x" | "y" | "zid">) => {
      if (!x || !y) return;
      setSelectedZip(zid);
      setCenter({ lat: y, lng: x });
      setZoom(12);
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <span>Zip Codes Management</span>
            <div className={cx("closeBtn")}>
               <button type="button" title="close" onClick={() => onClose()}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>
         <div className={cx("contentC")}>
            <div className={cx(`mapC`)} ref={ref}>
               <GoogleMapWrapper
                  center={center}
                  zoom={zoom}
                  mapProps={{
                     onBoundsChanged() {
                        console.log("bounds changed");
                     },
                  }}
               >
                  {/* {zipcodes
                  .filter((z) => z.area)
                  .map((z, i) => (
                     <PViSoSPolygon
                     key={i}
                     literals={parseWktToLatLngMultiPolygon(z.area || "")}
                     centerText={z.zid}
                     highLighBorder={selectedZip === z.zid}
                     onClick={() => setSelectedZip(z.zid)}
                     />
                     ))} */}
               </GoogleMapWrapper>
            </div>

            <div className={cx("zipCodeListC")}>
               <div className={cx("input_search")}>
                  <SoSInputTitle
                     value={search}
                     type="text"
                     handleValue={setSearch}
                     icon="search"
                     placeholder="Zip Code that starts with..."
                  />
               </div>

               <div className={cx("filter")}>
                  {qZipCodes.isLoading ? (
                     <div className={cx("loadingFilter")}>
                        Filter
                        <SHrSpinner />
                     </div>
                  ) : (
                     <SoSInputSelect
                        options={options}
                        value={selectedOption}
                        onChange={({ value }) => setSelectedOption(value)}
                        isMulti={false}
                        className={cx("filter-dropdown")}
                     />
                  )}
               </div>

               <div className={cx("list")}>
                  {qZipCodes.isLoading ? (
                     <div className={cx("loading")}>
                        <SHrSpinner /> <span>Loading Zip Codes...</span>
                     </div>
                  ) : !zipcodes.length ? (
                     <div className={cx("noData")}>No zip codes found</div>
                  ) : (
                     zipcodes.map((zip, index) => (
                        <PViZipCodeCard
                           key={index + zip.zid}
                           zid={zip.zid}
                           onClick={() => handleClickToZip(zip)}
                           isSelected={zip.zid === selectedZip}
                        />
                     ))
                     // <InfiniteScroll
                     //    pageStart={0}
                     //    loadMore={loadMore}
                     //    hasMore={hasMore}
                     //    loader={
                     //       <div className={cx("loading")} key={0}>
                     //          <SHrSpinner /> <span>Loading more Zip Codes...</span>
                     //       </div>
                     //    }
                     //    useWindow={false}
                     // >
                     //    {zipcodes.map((zip, index) => (
                     //       <PViZipCodeCard
                     //          key={index + zip.zid}
                     //          zid={zip.zid}
                     //          onClick={() => handleClickToZip(zip)}
                     //          isSelected={zip.zid === selectedZip}
                     //       />
                     //    ))}
                     // </InfiniteScroll>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

type PViZipCodesMapModalProps = {
   onClose: () => void;
};

// const parseWktToLatLngMultiPolygon = (wkt: string): google.maps.LatLngLiteral[][] => {
//    if (!wkt.startsWith("MULTIPOLYGON")) return [];

//    const rawPolygons = wkt.replace("MULTIPOLYGON(((", "").replace(")))", "").split(")),((");

//    return rawPolygons.map((polygon) =>
//       polygon.split(",").map((coord) => {
//          const [lng, lat] = coord.trim().split(" ").map(Number);
//          return { lat, lng };
//       })
//    );
// };

// const parseWktToLatLngPoint = (wkt: string): google.maps.LatLngLiteral => {
//    if (!wkt.startsWith("POINT")) return { lat: 0, lng: 0 };

//    const rawPoint = wkt.replace("POINT(", "").replace(")", "");

//    const [lng, lat] = rawPoint.split(" ").map(Number);
//    return { lat, lng };
// };
