import classNames from "classnames/bind";
import styles from "./TransactionTitleTab.module.scss";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { useTransactionOptions } from "../../../hooks/transactions/hooks/useTransactionOptions";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
import { formatDate } from "../TransactionInspectionTab/TransactionInspectionTab";
import { SoSUploaderContainer } from "../../../components/common/SoSUploaderContainer/SoSUploaderContainer";
import { SHrSosButton } from "@simplyhomes/react";

const cx = classNames.bind(styles);

export const TransactionTitleTab = () => {
   const { transaction, property } = useSelectedTransactionAndProperty();

   const { qEMDStatusOptions, qTitleCompanyStatusOptions, qClosingStatusOptions } = useTransactionOptions();

   if (!transaction) return null;

   const { emd_status, closing_status, title_status, properties, expire_date, emd_amount } = transaction;

   const { units } = property || {};

   return (
      <div className={cx("container")}>
         <div className={cx("mainStatusC")}>
            <div className={cx("status", "grayBg")}>
               <SoSDataPoint
                  type="bold-value"
                  title="EMD Amount"
                  value={emd_amount}
                  {...(emd_amount && { format: "currency" })}
               />
            </div>

            <div className={cx("status")}>
               <SoSDataPoint type="bold-title" title="EMD Due Date" value={"12/06/2024"} isMocked />
            </div>

            <div className={cx("status")}>
               <SoSDataPoint
                  type="bold-title"
                  title="EMD Status"
                  value={emd_status}
                  backGroundColor={qEMDStatusOptions?.find((option) => option.label === emd_status)?.color}
               />
            </div>
            <div className={cx("status")}>
               <SoSDataPoint type="bold-title" title="HUD Status" value={"Approved"} isMocked />
            </div>
         </div>

         <div className={cx("mainStatusC")}>
            <div className={cx("status")}>
               <SoSDataPoint
                  type="bold-title"
                  title="Title Status"
                  value={title_status}
                  backGroundColor={qTitleCompanyStatusOptions?.find((option) => option.label === title_status)?.color}
               />
            </div>

            <div className={cx("status")}>
               <SoSDataPoint
                  type="bold-title"
                  title="Closing Status"
                  value={closing_status}
                  backGroundColor={qClosingStatusOptions?.find((option) => option.label === closing_status)?.color}
               />
            </div>

            <div className={cx("status")}>
               <SoSDataPoint type="bold-title" title="Estimated Closing Date" value={"12/21/2024"} isMocked />
            </div>
            <div className={cx("status")}>
               <SoSDataPoint type="bold-title" title="Contract Expiration Date" value={formatDate(expire_date || "")} />
            </div>
         </div>

         <hr className={cx("divide")} />

         <span className={cx("heading")}>HUD Summary</span>

         <div className={cx("assetC")}>
            <div className={cx("asset", "grayBg")}>
               <SoSDataPoint type="bold-value" title="Contract Price" value={500} isMocked format="currency" />
            </div>
            <div className={cx("asset")}>
               <SoSDataPoint type="bold-title" title="Buyer (Owner Entity)" value={"Full Name"} isMocked />
               <SoSDataPoint type="only-value" value={"(000) 000 0000"} isMocked />
            </div>
            <div className={cx("asset")}>
               <SoSDataPoint type="bold-title" title="Assignment fee Contact" value={"Full Name"} isMocked />
               <SoSDataPoint type="only-value" value={"(000) 000 0000"} isMocked />
            </div>
         </div>

         <div className={cx("assetC")}>
            <div className={cx("asset", "grayBg")}>
               <SoSDataPoint
                  type="bold-value"
                  title="Settlement Charges to Buyer"
                  value={500}
                  isMocked
                  format="currency"
               />
            </div>
            <div className={cx("asset", "grayBg")}>
               <SoSDataPoint
                  type="bold-value"
                  title="Assignment fee to 3rd Party"
                  format="currency"
                  value={500}
                  isMocked
               />
            </div>
            <div className={cx("asset", "grayBg")}>
               <SoSDataPoint type="bold-value" title="Simply Homes fee" format="currency" value={500} isMocked />
            </div>
         </div>

         <hr className={cx("divide")} />

         <span className={cx("heading")}>Property Tax</span>

         <div className={cx("assetC")}>
            <div className={cx("asset")}>
               <SoSDataPoint type="bold-title" title="Property Tax Status" value={"Prepaid"} isMocked />
            </div>
         </div>

         <div className={cx("assetC")}>
            <div className={cx("asset", "grayBg")}>
               <SoSDataPoint
                  type="bold-value"
                  title="County Tax Prorated Amount"
                  value={500}
                  isMocked
                  format="currency"
               />
            </div>
            <div className={cx("asset", "grayBg")}>
               <SoSDataPoint
                  type="bold-value"
                  title="School Tax Prorated Amount"
                  format="currency"
                  value={500}
                  isMocked
               />
            </div>
            <div className={cx("asset", "grayBg")}>
               <SoSDataPoint
                  type="bold-value"
                  title="City Tax Prorated Amount"
                  format="currency"
                  value={500}
                  isMocked
               />
            </div>
         </div>

         <div className={cx("assetC")}>
            <div className={cx("asset")}>
               <SoSDataPoint type="bold-title" title="County Tax Date Period" value={"12/06/2024"} isMocked />
            </div>
            <div className={cx("asset")}>
               <SoSDataPoint type="bold-title" title="School Tax Prorated Amount" value={"12/06/2024"} isMocked />
            </div>
            <div className={cx("asset")}>
               <SoSDataPoint type="bold-title" title="City Tax Prorated Amount" value={"12/06/2024"} isMocked />
            </div>
         </div>

         <hr className={cx("divide")} />

         <span className={cx("heading")}>Leasing Details</span>

         <TransactionPropertySwitch properties={properties} />

         <div className={cx("rentC")}>
            <div className={cx("rentMainStatusC")}>
               <div className={cx("status")}>
                  <SoSDataPoint
                     format="currency"
                     type="bold-value"
                     title="Total Prorated Rent Amount"
                     value={500}
                     isMocked
                  />
               </div>

               <div className={cx("status")}>
                  <SoSDataPoint
                     type="bold-value"
                     title="Total Security Deposit"
                     value={500}
                     isMocked
                     format="currency"
                  />
               </div>
            </div>

            {units?.map((unit, index) => (
               <div className={cx("unitC")} key={index}>
                  <div className={cx("unit")}>
                     <div className={cx("listItem")}>
                        <div className={cx("dot")} />
                        <span>
                           <b>Unit {index + 1}</b>
                        </span>
                     </div>

                     <div className={cx("divider-container")}>
                        <hr className={cx("divider")} />
                     </div>

                     <div className={cx("data")}>
                        <SoSDataPoint type="bold-title" title="Occupancy" value={"Occupied"} isMocked />
                     </div>

                     <div className={cx("data")}>
                        <SoSDataPoint
                           type="bold-title"
                           title="Lease Cycle"
                           value={"17/10/2024"}
                           inputType="date"
                           isMocked
                        />
                     </div>

                     <div className={cx("data")}>
                        <SoSDataPoint type="bold-value" title="Monthly Rent" value={500} format="currency" isMocked />
                     </div>

                     <div className={cx("data")}>
                        <SoSDataPoint
                           type="bold-value"
                           title="Security Deposit"
                           value={500}
                           format="currency"
                           isMocked
                        />
                     </div>
                  </div>
               </div>
            ))}
         </div>

         <hr className={cx("divide")} />

         <span className={cx("heading")}> Related Documents</span>

         <div className={cx("notesC")}>
            <div className={cx("SOWC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>EMD Receipt</b>
                  </span>
               </div>

               <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                  <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                     Upload a file
                  </SHrSosButton>
               </SoSUploaderContainer>
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <div className={cx("SOWC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>HUD</b>
                  </span>
               </div>

               <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                  <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                     Upload a file
                  </SHrSosButton>
               </SoSUploaderContainer>
            </div>
         </div>

         <hr className={cx("divide")} />

         <div className={cx("notesC")}>
            <div className={cx("SOWC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>Deed</b>
                  </span>
               </div>

               <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                  <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                     Upload a file
                  </SHrSosButton>
               </SoSUploaderContainer>
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <div className={cx("SOWC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>Liens</b>
                  </span>
               </div>

               <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                  <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                     Upload a file
                  </SHrSosButton>
               </SoSUploaderContainer>
            </div>
         </div>

         <hr className={cx("divide")} />

         <div className={cx("notesC")}>
            <div className={cx("SOWC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>Inspection SOW</b>
                  </span>
               </div>

               <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                  <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                     Upload a file
                  </SHrSosButton>
               </SoSUploaderContainer>
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <div className={cx("SOWC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>Disclosures</b>
                  </span>
               </div>

               <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                  <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                     Upload a file
                  </SHrSosButton>
               </SoSUploaderContainer>
            </div>
         </div>

         <hr className={cx("divide")} />

         <div className={cx("notesC")}>
            <div className={cx("SOWC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>Leases</b>
                  </span>
               </div>

               <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                  <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                     Upload a file
                  </SHrSosButton>
               </SoSUploaderContainer>
            </div>
            <div className={cx("divider-container")}>
               <hr className={cx("divider")} />
            </div>
            <div className={cx("SOWC")}>
               <div className={cx("headingC")}>
                  <span>
                     <b>Estoppels</b>
                  </span>
               </div>

               <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                  <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                     Upload a file
                  </SHrSosButton>
               </SoSUploaderContainer>
            </div>
         </div>
      </div>
   );
};
