import HomePage from "../pages/HomePage/HomePage";
import MainLayout from "../layouts/MainLayout/MainLayout";
import ApplicationLibraryPage from "../pages/ApplicationLibraryPage/ApplicationLibraryPage";
import { constants_paths } from "../constants/path";
import MapPage from "../pages/MapPage/MapPage";
import QuestionEditorPage from "../pages/QuestionEditorPage/QuestionEditorPage";
import { Dev } from "../pages/dev/Dev";
import CsvUploadPage from "../pages/CsvUploadPage/CsvUploadPage";
import { PropertyViabilityPage } from "../pages/PropertyViabilityPage/PropertyViabilityPage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import { DevJim } from "../pages/devJim/DevJim";
import { DevNick } from "../pages/devNick/DevNick";
import { DevOcean } from "../pages/devOcean/DevOcean";
import { DevTimmy } from "../pages/devTimmy/DevTimmy";
import { OffersPage } from "../pages/OffersPage/OffersPage";
import { TransactionPage } from "../pages/TransactionPage/TransactionPage";
import { SignInPage } from "../pages/SignInPage/SignInPage";
import { Route, Routes } from "react-router-dom";

export const PublicAppRoutes = () => (
   <Routes>
      <Route path={constants_paths.SIGN_IN} element={<SignInPage />} />
      <Route path={constants_paths.UNAUTHROIZED} element={<ErrorPage type={"Unauthorized"} />} />
      <Route path={constants_paths.FORBIDDEN} element={<ErrorPage type={"Forbidden"} />} />
      <Route path={constants_paths.SOMETHING_WRONG} element={<ErrorPage type={"InternalServerError"} />} />
      <Route path="*" element={<ErrorPage type={"NotFound"} />} />
   </Routes>
);

const ML = (e: React.ReactNode) => <MainLayout>{e}</MainLayout>;
export const PrivateAppRoutes = () => {
   return (
      <Routes>
         <Route path={constants_paths.HOME} element={ML(<HomePage />)} />
         <Route path={constants_paths.APPLICATION_LIBRARY} element={ML(<ApplicationLibraryPage />)} />
         <Route path={constants_paths.OFFERS} element={ML(<OffersPage />)}>
            <Route path=":lid" />
         </Route>
         <Route path={constants_paths.APPS_MAP} element={ML(<MapPage />)} />
         <Route path={constants_paths.APPS_CSV_UPLOAD} element={ML(<CsvUploadPage />)} />
         <Route path={constants_paths.APPS_QEDITOR} element={ML(<QuestionEditorPage />)} />
         <Route path={constants_paths.APPS_TRANSACTION} element={ML(<TransactionPage />)} />
         <Route path={constants_paths.PROPERTY_VIABILITY} element={ML(<PropertyViabilityPage />)}>
            <Route path=":pid" />
         </Route>
         <Route path={constants_paths.DEV} element={ML(<Dev />)} />
         <Route path={constants_paths.DEVJIM} element={ML(<DevJim />)} />
         <Route path={constants_paths.DEVNICK} element={ML(<DevNick />)} />
         <Route path={constants_paths.DEVOCEAN} element={ML(<DevOcean />)} />
         <Route path={constants_paths.DEVTIMMY} element={ML(<DevTimmy />)} />
      </Routes>
   );
};
