import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import styles from "./PViAskingPriceFilter.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { useClickOutside } from "../../../hooks/react/useClickOutside";
import { useQPVi_FilterOptions } from "../../../hooks/property-viability/queries/useQPVi_FilterOptions";
import { usePViQueryParams } from "../../../hooks/property-viability/hooks/usePViQueryParams";
import { SHrSosButton } from "@simplyhomes/react";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";

const cx = classNames.bind(styles);

export const PViAskingPriceFilter = () => {
   const qpPVi = usePViQueryParams();
   const {
      setMinSourcePrice,
      setMaxSourcePrice,
      minSourcePrice: minSourcePriceParam,
      maxSourcePrice: maxSourcePriceParam,
      removeParams,
   } = qpPVi;
   const filterOptions = useQPVi_FilterOptions().data;

   const askingPriceData =
      filterOptions?.numberPropertyBySourcePrice?.toSorted((a, b) => b.sourcePrice - a.sourcePrice) || [];

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const { ref: clickOutSideRef } = useClickOutside({ onClickOutside: () => setIsDropdownOpen(false) });

   const [minValue, setMinValue] = useState(+minSourcePriceParam || 0);
   const [maxValue, setMaxValue] = useState(askingPriceData[0]?.sourcePrice || 0);

   const minSourcePrice = 0;
   const maxSourcePrice = 350000;
   const step = 10000;

   useEffect(() => {
      const value = maxSourcePriceParam ? +maxSourcePriceParam : maxSourcePrice;
      setMaxValue(value);
   }, [maxSourcePrice, maxSourcePriceParam]);

   const isTouched = minSourcePriceParam || maxSourcePriceParam;

   const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const valueMin = Math.min(Number(event.target.value), maxValue - step);
      setMinValue(valueMin);
      setMinSourcePrice(valueMin.toString());
   };

   const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Math.max(Number(event.target.value), minValue + step);
      setMaxValue(value);
      setMaxSourcePrice(value.toString());
   };

   const handleClearFilter = () => {
      setMinSourcePrice("");
      setMaxSourcePrice("");
      setMinValue(minSourcePrice);
      setMaxValue(maxSourcePrice);
      removeParams(["minSourcePrice", "maxSourcePrice"]);
   };

   return (
      <div className={cx("notification")} ref={clickOutSideRef}>
         <span
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={cx("dropdown-title", {
               "drowdown-title-active": isTouched,
            })}
         >
            Asking price
            {isTouched && (
               <img
                  src={CF_CDN_URL("/assets/clear_black.svg")}
                  alt=""
                  onClick={(e) => {
                     e.stopPropagation();
                     handleClearFilter();
                  }}
               />
            )}
         </span>
         <div
            className={cx("avt-notification")}
            onClick={(e) => {
               e.stopPropagation();
               setIsDropdownOpen(!isDropdownOpen);
            }}
         >
            <img src={CF_CDN_URL("/assets/expand_more_black.svg")} alt="" />
         </div>
         <div className={cx("menu-item", { open: isDropdownOpen })}>
            <div className={cx("range-slider")}>
               {/* <div className={cx("barchart")}>
                  {askingPriceData.map((d, i) => {
                     return (
                        <div
                           key={i}
                           className={cx("barchart-col")}
                           style={{
                              width: `${columnWidth}%`,
                              height: `${(d.numberProperty * 100) / maxNumberProperty}%`,
                              left: `calc(${(d.sourcePrice * 100) / maxSourcePrice - columnWidth}%)`,
                           }}
                        ></div>
                     );
                  })}
               </div> */}
               <div className={cx("priceBtn")}>
                  <SHrSosButton
                     type="bland"
                     buttonProps={{
                        onClick: () => {
                           setMinValue(0);
                           setMinSourcePrice("0");
                        },
                     }}
                  >
                     Min
                  </SHrSosButton>
                  <SHrSosButton
                     type="bland"
                     buttonProps={{
                        onClick: () => {
                           setMaxValue(350000);
                           setMaxSourcePrice("350000");
                        },
                     }}
                  >
                     Max
                  </SHrSosButton>
               </div>
               <input
                  title="min"
                  type="range"
                  min={minSourcePrice}
                  max={maxSourcePrice}
                  step={step}
                  value={minValue}
                  onChange={handleMinChange}
                  className={cx("range-slider__thumb", "range-slider__thumb--left")}
               />
               <input
                  title="max"
                  type="range"
                  min={minSourcePrice}
                  max={maxSourcePrice}
                  step={step}
                  value={maxValue}
                  onChange={handleMaxChange}
                  className={cx("range-slider__thumb", "range-slider__thumb--right")}
               />

               <div className={cx("range-slider__track")}></div>
               <div
                  className={cx("range-slider__range")}
                  style={{
                     left: `${((minValue - minSourcePrice) / (maxSourcePrice - minSourcePrice)) * 100}%`,
                     right: `${((maxSourcePrice - maxValue) / (maxSourcePrice - minSourcePrice)) * 100}%`,
                  }}
               ></div>
            </div>
            <div className={cx("range-slider__values")}>
               <div className={cx("range-slider-min")}>
                  {/* <div className={cx("input-label")}>Min Price</div>
                  <input
                     readOnly
                     title="min"
                     type="text"
                     value={SHu_js_currencyFormat(minValue || 0, { maximumFractionDigits: 0 })}
                     className={cx("input", "input-min")}
                     id="min"
                  /> */}
                  {/* <label htmlFor="min"></label> */}
                  <SoSDataPoint
                     title="Min Price"
                     type="bold-value"
                     format="currency"
                     value={minValue || 0}
                     onNonDatabaseChange={(value) => {
                        setMinValue(Number(value));
                        setMinSourcePrice(value.toString());
                     }}
                  />
               </div>
               <div>
                  {/* <div className={cx("input-label")}>Max Price</div>
                  <input
                     readOnly
                     title="max"
                     type="text"
                     value={SHu_js_currencyFormat(maxValue || 0, { maximumFractionDigits: 0 })}
                     className={cx("input", "input-max")}
                     id="max"
                  />
                  <label htmlFor="max"></label> */}
                  <SoSDataPoint
                     title="Max Price"
                     type="bold-value"
                     value={maxValue || 0}
                     format="currency"
                     onNonDatabaseChange={(value) => {
                        setMaxValue(Number(value));
                        setMaxSourcePrice(value.toString());
                     }}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};
