import classNames from "classnames/bind";
import styles from "./OffersSimplyTerms.module.scss";
import { SoSInputTitle } from "../../../../components/common/soSInputTitle/SoSInputTitle";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SoSInputSelect } from "../../../../components/common/SoSInputSelect/SoSInputSelect";
import { useQSoS_Options, useSoSOptions_Offers_Simply_Terms } from "../../../../hooks/queries/options/useQSoS_Options";
import { useMemo } from "react";
import { TSoS_TermTemplateSimply } from "../../../../hooks/offers/queries/termTemplate/useQOffers_GetTermTemplateById";
import { OffersTermTemplateActions } from "../OffersEditTermTemplateModal";

const cx = classNames.bind(styles);

type TRowSimplyTermProps = {
   index: number;
   termTemplateData: TSoS_TermTemplateSimply;
} & Omit<OffersTermTemplateActions, "additionalTermsActions">;

export const RowSimplyTerm = ({ index, termTemplateData, simplyTermsActions }: TRowSimplyTermProps) => {
   const termTemplateItem = termTemplateData.find((_, i) => i === index);

   const { data: simplyTermsData } = useSoSOptions_Offers_Simply_Terms();
   const { data: simplyTermsDataRight } = useQSoS_Options({ moid: `sos_simply_term_${termTemplateItem?.title || ""}` });

   const simpyTermsValue = useMemo(
      () =>
         simplyTermsData?.options.filter((option) => !termTemplateData.map((s) => s.title).includes(option.value)) ||
         [],
      [simplyTermsData?.options, termTemplateData]
   );

   const simplyTermsRight = useMemo(() => simplyTermsDataRight?.options || [], [simplyTermsDataRight?.options]);

   if (!termTemplateItem) return null;

   const { title, default: defaultValue } = termTemplateItem;

   return (
      <div className={cx("simplyTermsC")}>
         <div className={cx("leftInputTermsC")}>
            <div className={cx("img-icon")}>
               <img src={CF_CDN_URL("/assets/drag_indicator_black.svg")} alt="" />
            </div>
            <div className={cx("input-select")}>
               <SoSInputSelect
                  title="Type"
                  isMulti={false}
                  value={simplyTermsData?.options.find((t) => t.value === title)?.label || ""}
                  onChange={(o) => simplyTermsActions.onUpdate(index, o.value, "")}
                  options={simpyTermsValue}
                  isLoading={!simpyTermsValue}
                  SelectProps={{ isSearchable: false }}
               />
            </div>
         </div>
         <div className={cx("rightInputTermsC")}>
            <div className={cx("input-select")}>
               {simplyTermsRight.length ? (
                  <SoSInputSelect
                     title="Default"
                     isMulti={false}
                     value={simplyTermsRight.find((t) => t.value === defaultValue)?.label || ""}
                     onChange={(o) => simplyTermsActions.onUpdate(index, title, o.value)}
                     options={simplyTermsRight}
                     isLoading={!simplyTermsRight.length}
                     SelectProps={{ isSearchable: false }}
                  />
               ) : (
                  <SoSInputTitle
                     type="text"
                     title="Default"
                     value={defaultValue as string}
                     handleValue={(v) => simplyTermsActions.onUpdate(index, title, v)}
                  />
               )}
            </div>
            <div className={cx("img-icon", "delete")} onClick={() => simplyTermsActions.onRemove(index)}>
               <img src={CF_CDN_URL("/assets/delete_outline_black.svg")} alt="" />
            </div>
         </div>
      </div>
   );
};
