import classNames from "classnames/bind";
import { useRef } from "react";
import styles from "./SoSUploaderWrapper.module.scss";
import { ReactNode, useState } from "react";
import { useToastContext } from "../../contexts/ToastContext";

const cx = classNames.bind(styles);

const allowedImagesTypes = [
   "image/png",
   "image/svg+xml",
   "image/jpg",
   "image/jpeg",
   "image/heic",
   "image/heif",
   "image/webp",
   "image/gif",
];

export const SoSUploaderWrapper = ({ type, children, onUpload }: IUploaderWrapperBaseProps) => {
   const fileInputRef = useRef<HTMLInputElement>(null);
   const { toastDispatch } = useToastContext();

   const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;

      const newFiles = e.target.files;

      // validate images types
      if (type === "images") {
         for (let i = 0; i < newFiles.length; i++) {
            if (!allowedImagesTypes.includes(newFiles[i].type)) {
               toastDispatch({
                  type: "ADD_TOAST",
                  payload: {
                     type: "error",
                     message: "Invalid image type",
                  },
               });
               return;
            }
         }
      }

      const dataTransfer = new DataTransfer();

      for (let i = 0; i < newFiles.length; i++) dataTransfer.items.add(newFiles[i]);

      onUpload(dataTransfer.files);
   };

   return (
      <div className={cx("container")}>
         {type === "files" && (
            <div className={cx("inputUploadC")}>
               <input
                  ref={fileInputRef}
                  title="file-upload"
                  type="file"
                  multiple
                  onChange={handleFilesChange}
                  style={{ display: "none" }}
               />
               <div onClick={() => fileInputRef.current?.click()}>{children}</div>
            </div>
         )}
         {type === "images" && (
            <div className={cx("uploadBtnC")}>
               <input
                  ref={fileInputRef}
                  type="file"
                  alt="Upload image"
                  multiple
                  accept={allowedImagesTypes.join(",")}
                  onChange={handleFilesChange}
                  style={{ display: "none" }}
               />
               <div onClick={() => fileInputRef.current?.click()}>{children}</div>
            </div>
         )}
      </div>
   );
};

type IUploaderWrapperBaseProps = {
   type: "files" | "images";
   children: ReactNode;
   onUpload: (files: FileList) => void;
};
