import { useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../../contexts/ToastContext";
import { useSosAxios } from "../../../axios/useSosAxios";
import { useQPVi_Property } from "../../queries/useQPVi_Property";

export const useMPVi_Submit_Property_To_AirTable = () => {
   const { sosAxios } = useSosAxios();
   const { toastDispatch } = useToastContext();

   const qPVi_Property = useQPVi_Property();

   return useMutation({
      onSuccess: () => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Create a new property/lead on Airtable is successful!",
            },
         });
         qPVi_Property.refetch();
      },
      onError: (error) =>
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Create a new property/lead on Airtable is failed!",
            },
         }),
      mutationFn: ({ pid }: PVi_SubmitPropertyIntoAT_Param) =>
         sosAxios
            .post(`/property-viability/properties/${pid}/submit-into-airtable`)
            .then<PVi_SubmitPropertyIntoAT_Response>(({ data }) => data),
   });
};
export class PVi_SubmitPropertyIntoAT_Param {
   pid!: string;
}

export class PVi_SubmitPropertyIntoAT_Response {
   message!: string;
}
