import { useMemo, useState } from "react";
import classNames from "classnames/bind";

import styles from "./OffersEditTermTemplateModal.module.scss";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SHrSosButton, SHrSosTabsBar, SHrSpinner } from "@simplyhomes/react";
import { SoSConfirmWrapper } from "../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { SoSInputSelect, TSoSInputSelect_option } from "../../../components/common/SoSInputSelect/SoSInputSelect";
import { useOffersContext } from "../../../contexts/OffersContext";
import { RowAdditionalTerms } from "./OffersAdditionalTerms/OffersAdditionalTerms";
import { useQOffers_GetTermTemplates } from "../../../hooks/offers/queries/termTemplate/useQOffers_GetTermTemplates";
import {
   SoS_TermTemplate,
   useQOffers_GetTermTemplateById,
} from "../../../hooks/offers/queries/termTemplate/useQOffers_GetTermTemplateById";
import {
   SoS_CreateTermTemplate_Response,
   useMTT_CreateTermTemplate,
} from "../../../hooks/offers/mutates/termTemplate/useMTT_CreateTermTemplate";
import { useMTT_UpdateTermTemplate } from "../../../hooks/offers/mutates/termTemplate/useMTT_UpdateTermTemplate";
import { RowSimplyTerm } from "./OffersSimplyTerms/OffersSimplyTerms";
import { useQOffers_Lead } from "../../../hooks/offers/queries/leads/useQOffers_GetLead";
import { useMOffers_Leads_UpdateLead } from "../../../hooks/offers/mutates/leads/useMOffers_Leads_UpdateLead";
import { useToastContext } from "../../../contexts/ToastContext";
import { useQOffers_GetLeads } from "../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import { OffersCreateAndUpdateOfferTermTemplate } from "../OffersCreateAndUpdateOfferModal/OffersCreateAndUpdateOfferModal";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

const cx = classNames.bind(styles);

type OffersEditTermTemplateOptionProps = {
   ttid: number;
   ttName: string;
   onSelectTermTemplateChange: (value: string) => void;
   onSuccessCreateNewTermTemplate: (data: SoS_CreateTermTemplate_Response) => void;
   onSuccessUpdateTermTemplate: () => void;
};

export const OffersEditTermTemplateOption = ({
   ttid,
   ttName,
   onSelectTermTemplateChange,
   onSuccessCreateNewTermTemplate,
   onSuccessUpdateTermTemplate,
}: OffersEditTermTemplateOptionProps) => {
   const qOfferTermTemplates = useQOffers_GetTermTemplates();
   const updateTermTemplates = useMTT_UpdateTermTemplate({
      onSuccess: onSuccessUpdateTermTemplate,
      onError: (error: string) => console.log(error),
   });
   const createTermTemplates = useMTT_CreateTermTemplate({
      onSuccess: onSuccessCreateNewTermTemplate,
      onError: (error: string) => console.log(error),
   });

   const termTemplateOptions: TSoSInputSelect_option[] = useMemo(
      () => qOfferTermTemplates.data?.templates.map((t) => ({ label: t?.name, value: t?.tid.toString() })) || [],
      [qOfferTermTemplates.data?.templates]
   );

   const handleCreateNewTermTemplate = (value: string) => createTermTemplates.mutate({ name: value });

   return (
      <div className={cx("input-search")}>
         <SoSInputSelect
            placeholder="Template Name"
            isMulti={false}
            value={{
               label: ttName,
               value: ttid.toString(),
            }}
            onChange={(o) => onSelectTermTemplateChange(o.value)}
            options={termTemplateOptions}
            isLoading={!termTemplateOptions.length || updateTermTemplates.isPending || createTermTemplates.isPending}
            allowCreate={{ cb: ({ value }) => handleCreateNewTermTemplate(value) }}
            {...(ttid && { allowUpdate: { cb: ({ value }) => updateTermTemplates.mutate({ ttid, name: value }) } })}
         />
      </div>
   );
};

export type OffersTermTemplateActions = {
   simplyTermsActions: {
      onAddNew: () => void;
      onUpdate: (index: number, title: string, defaultValue: string) => void;
      onRemove: (index: number) => void;
   };
   additionalTermsActions: {
      onAddNew: () => void;
      onUpdate: (index: number, value: string) => void;
      onRemove: (index: number) => void;
   };
};

type OffersEditTermTemplateTabDataProps = {
   termTemplateData: SoS_TermTemplate;
   onSave: () => void;
   termTemplateDataLoading?: boolean;
   loading?: boolean;
} & OffersTermTemplateActions;

export const OffersEditTermTemplateTabData = ({
   termTemplateData,
   termTemplateDataLoading = false,
   onSave,
   loading,
   simplyTermsActions,
   additionalTermsActions,
}: OffersEditTermTemplateTabDataProps) => {
   const { offersDispatch } = useOffersContext();

   const [activeTab, setActiveTab] = useState("Simply Terms");

   const handleAddNewItemByTab = () => {
      if (activeTab === "Simply Terms") simplyTermsActions.onAddNew();
      else additionalTermsActions.onAddNew();
   };

   const handleDragEnd = (result: DropResult) => {
      if (!result.destination) return;

      const sourceList = activeTab === "Simply Terms" ? termTemplateData.simply : termTemplateData.additional;
      const destinationList = [...sourceList];

      const [reorderedItem] = destinationList.splice(result.source.index, 1);
      destinationList.splice(result.destination.index, 0, reorderedItem);

      offersDispatch({
         overwrite: {
            termTemplateData: {
               ...termTemplateData,
               [activeTab === "Simply Terms" ? "simply" : "additional"]: destinationList,
            },
         },
      });
   };

   if (termTemplateDataLoading) {
      return <SHrSpinner />;
   }

   return termTemplateData ? (
      <>
         <div className={cx("tabs")}>
            <SHrSosTabsBar tabs={tabs} value={activeTab} onChange={setActiveTab} />
            <SHrSosButton
               type="text"
               buttonProps={{
                  className: cx("btn"),
                  onClick: () => handleAddNewItemByTab(),
               }}
            >
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/add_box_black.svg")} alt="" />
               </div>
               Add new Term
            </SHrSosButton>
         </div>

         <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable-terms">
               {(provided) => (
                  <div className={cx("tearmsSetC")} {...provided.droppableProps} ref={provided.innerRef}>
                     {termTemplateDataLoading && (
                        <div className={cx("spinnerC")}>
                           <SHrSpinner />
                        </div>
                     )}

                     {activeTab === "Simply Terms" &&
                        termTemplateData.simply.map((item, i) => (
                           <Draggable key={i} draggableId={`draggable-${i}`} index={i}>
                              {(provided) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                 >
                                    <RowSimplyTerm
                                       key={i}
                                       index={i}
                                       termTemplateData={termTemplateData.simply}
                                       simplyTermsActions={{ ...simplyTermsActions }}
                                    />
                                 </div>
                              )}
                           </Draggable>
                        ))}

                     {activeTab === "Additional Terms" &&
                        termTemplateData.additional.map((_, i) => (
                           <Draggable key={i} draggableId={`draggable-${i}`} index={i}>
                              {(provided) => (
                                 <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                 >
                                    <RowAdditionalTerms
                                       key={i}
                                       index={i}
                                       termTemplateData={termTemplateData.additional}
                                       additionalTermsActions={{ ...additionalTermsActions }}
                                    />
                                 </div>
                              )}
                           </Draggable>
                        ))}
                     {provided.placeholder}
                  </div>
               )}
            </Droppable>
         </DragDropContext>

         <SoSConfirmWrapper
            title="Are you sure you want to save the term template?"
            content="This action allows to update the changed last in the term template."
            type="default"
            onConfirm={() => onSave && onSave()}
         >
            <div className={cx("btnSave")}>
               <SHrSosButton loading={loading} buttonProps={{ className: cx("") }}>
                  Save
               </SHrSosButton>
            </div>
         </SoSConfirmWrapper>
      </>
   ) : (
      <div className={cx("noDataC")}>No Selected Term Template!</div>
   );
};

export const OffersEditTermTemplateModal = () => {
   const { offersState, offersDispatch } = useOffersContext();
   const { toastDispatch } = useToastContext();
   const { showDetailsModal, termTemplateData, ttid, ttName } = offersState;
   const qOfferTermTemplates = useQOffers_GetTermTemplates();

   const qOfferTermTemplate = useQOffers_GetTermTemplateById({
      ttid,
      cb: (data) => {
         offersDispatch({
            overwrite: {
               ttName: data.template.name,
               termTemplateData: data.template.data,
            },
         });
      },
   });

   const updateTermTemplates = useMTT_UpdateTermTemplate({
      onSuccess: () => {
         qOfferTermTemplate.refetch();
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Update term template is successful!",
            },
         });
      },
      onError: (error: string) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Update term template is failed!",
            },
         });
      },
   });

   const handleAddNewSimplyTermsItem = () =>
      offersDispatch({
         type: "add-new-simply-term-item",
      });

   const handleUpdateSimplyTermsItem = (index: number, title: string, defaultValue: string) =>
      offersDispatch({
         type: "update-simply-term-item",
         payload: { index, title, default: defaultValue },
      });

   const handleRemoveSimplyTermsItem = (index: number) =>
      offersDispatch({ type: "remove-simply-term-item", payload: { index } });

   const handleAddNewAdditionalTermsItem = () =>
      offersDispatch({
         type: "add-new-additional-term-item",
      });

   const handleUpdateAdditionalTermsItem = (index: number, value: string) =>
      offersDispatch({
         type: "update-additional-term-item",
         payload: { index, value },
      });

   const handleRemoveAdditionalTermsItem = (index: number) =>
      offersDispatch({ type: "remove-additional-term-item", payload: { index } });

   const handleCreateNewTermTemplateSuccess = ({
      template: { tid: ttid, name: ttName },
   }: SoS_CreateTermTemplate_Response) => {
      qOfferTermTemplates.refetch();
      offersDispatch({ overwrite: { ttid, ttName } });
      toastDispatch({
         type: "ADD_TOAST",
         payload: {
            type: "success",
            message: "Create a new term template is successful!",
         },
      });
   };

   const handleUpdateTermTemplateSuccess = () => {
      qOfferTermTemplates.refetch();
      qOfferTermTemplate.refetch();
      toastDispatch({
         type: "ADD_TOAST",
         payload: {
            type: "success",
            message: "Update term template is successful!",
         },
      });
   };

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("headingLeftC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/list_black.svg")} alt="" />
               </div>
               <span>Edit Terms Template</span>
            </div>
            <div className={cx("headingRightC")}>
               <OffersEditTermTemplateOption
                  ttid={ttid}
                  ttName={ttName}
                  onSelectTermTemplateChange={(value) => offersDispatch({ overwrite: { ttid: +value } })}
                  onSuccessCreateNewTermTemplate={handleCreateNewTermTemplateSuccess}
                  onSuccessUpdateTermTemplate={handleUpdateTermTemplateSuccess}
               />
               <div className={cx("closeBtn")}>
                  <button
                     type="button"
                     title="close"
                     onClick={() => offersDispatch({ overwrite: { showDetailsModal: !showDetailsModal } })}
                  >
                     <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                  </button>
               </div>
            </div>
         </div>
         {ttid > 0 && (
            <OffersEditTermTemplateTabData
               termTemplateData={termTemplateData}
               onSave={() => updateTermTemplates.mutate({ ttid, name: ttName, termTemplateData })}
               termTemplateDataLoading={qOfferTermTemplate.isLoading && qOfferTermTemplate.isFetching}
               loading={updateTermTemplates.isPending}
               simplyTermsActions={{
                  onAddNew: handleAddNewSimplyTermsItem,
                  onUpdate: handleUpdateSimplyTermsItem,
                  onRemove: handleRemoveSimplyTermsItem,
               }}
               additionalTermsActions={{
                  onAddNew: handleAddNewAdditionalTermsItem,
                  onUpdate: handleUpdateAdditionalTermsItem,
                  onRemove: handleRemoveAdditionalTermsItem,
               }}
            />
         )}
      </div>
   );
};

type OffersEditTermTemplateLeadDetailModalProps = {
   onClose: () => void;
};

export const OffersEditTermTemplateLeadDetailModal = ({ onClose }: OffersEditTermTemplateLeadDetailModalProps) => {
   const { toastDispatch } = useToastContext();
   const { offersState, offersDispatch } = useOffersContext();
   const { leadTermTemplateData } = offersState;

   const qOffersGetLeads = useQOffers_GetLeads();
   const qOffersGetLead = useQOffers_Lead();

   const updateLeadMutate = useMOffers_Leads_UpdateLead({
      onSuccess: () => {
         qOffersGetLeads.refetch();
         qOffersGetLead.refetch();
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "success",
               message: "Update term template in lead is successful!",
            },
         });
      },
      onError: (error: string) => {
         toastDispatch({
            type: "ADD_TOAST",
            payload: {
               type: "error",
               message: "Update term template in lead is failed!",
            },
         });
      },
   });

   const handleAddNewSimplyTermsItem = () =>
      offersDispatch({
         type: "add-new-simply-term-item-in-lead-detail",
      });

   const handleUpdateSimplyTermsItem = (index: number, title: string, defaultValue: string) =>
      offersDispatch({
         type: "update-simply-term-item-in-lead-detail",
         payload: { index, title, default: defaultValue },
      });

   const handleRemoveSimplyTermsItem = (index: number) =>
      offersDispatch({ type: "remove-simply-term-item-in-lead-detail", payload: { index } });

   const handleAddNewAdditionalTermsItem = () =>
      offersDispatch({
         type: "add-new-additional-term-item-in-lead-detail",
      });

   const handleUpdateAdditionalTermsItem = (index: number, value: string) =>
      offersDispatch({
         type: "update-additional-term-item-in-lead-detail",
         payload: { index, value },
      });

   const handleRemoveAdditionalTermsItem = (index: number) =>
      offersDispatch({ type: "remove-additional-term-item-in-lead-detail", payload: { index } });

   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("headingLeftC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/list_black.svg")} alt="" />
               </div>
               <span>Edit Terms Template</span>
            </div>
            <div className={cx("headingRightC")}>
               <div className={cx("closeBtn")}>
                  <button type="button" title="close" onClick={() => onClose()}>
                     <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                  </button>
               </div>
            </div>
         </div>

         <OffersEditTermTemplateTabData
            termTemplateData={leadTermTemplateData}
            onSave={() => updateLeadMutate.mutate({})}
            loading={updateLeadMutate.isPending}
            simplyTermsActions={{
               onAddNew: handleAddNewSimplyTermsItem,
               onUpdate: handleUpdateSimplyTermsItem,
               onRemove: handleRemoveSimplyTermsItem,
            }}
            additionalTermsActions={{
               onAddNew: handleAddNewAdditionalTermsItem,
               onUpdate: handleUpdateAdditionalTermsItem,
               onRemove: handleRemoveAdditionalTermsItem,
            }}
         />
      </div>
   );
};

type OffersEditTermTemplateCreateOfferModalProps = {
   termTemplate: OffersCreateAndUpdateOfferTermTemplate;
   onUpdateTermTemplate: () => void;
   onClose: () => void;
} & OffersTermTemplateActions;

export const OffersEditTermTemplateCreateOfferModal = ({
   termTemplate: { termTemplateData },
   simplyTermsActions,
   onUpdateTermTemplate,
   additionalTermsActions,
   onClose,
}: OffersEditTermTemplateCreateOfferModalProps) => {
   return (
      <div className={cx("container")}>
         <div className={cx("headingC")}>
            <div className={cx("headingLeftC")}>
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/offer/list_black.svg")} alt="" />
               </div>
               <span>Edit Terms Template</span>
            </div>
            <div className={cx("headingRightC")}>
               <div className={cx("closeBtn")}>
                  <button type="button" title="close" onClick={() => onClose()}>
                     <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                  </button>
               </div>
            </div>
         </div>
         <OffersEditTermTemplateTabData
            termTemplateData={termTemplateData}
            simplyTermsActions={simplyTermsActions}
            onSave={() => onUpdateTermTemplate()}
            additionalTermsActions={additionalTermsActions}
         />
      </div>
   );
};

const tabs = ["Simply Terms", "Additional Terms"];
