import classNames from "classnames/bind";
import styles from "./PViRejectModal.module.scss";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import { SoSCheckbox } from "../../../../components/common/SoSCheckbox/SoSCheckbox";
import { SHrSosButton, SHrSpinner } from "@simplyhomes/react";
import { useState } from "react";
import { useSoSOptions_PVi_Source_Reject_Reasons } from "../../../../hooks/queries/options/useQSoS_Options";
import { useMPVi_UpdateProperty } from "../../../../hooks/property-viability/mutates/properties/useMPVi_UpdateProperty";
import { useQPVi_Property } from "../../../../hooks/property-viability/queries/useQPVi_Property";
import { SoSConfirmWrapper } from "../../../../components/common/SoSConfirmWrapper/SoSConfirmWrapper";
import { ESoS_PropertyStatus } from "@simplyhomes/utils";
import { useQPVi_GetPreRejectProperties } from "../../../../hooks/property-viability/queries/useQPVi_PreRejectProperties";

const cx = classNames.bind(styles);

export const PViRejectModal = ({ toggle }: TRejectionProps) => {
   const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

   const [rejectDetail, setRejectDetail] = useState<string>("");

   const { mutateAsync: mutateUpdateProperty, isMutating } = useMPVi_UpdateProperty();

   const qPViSourceRejectReasons = useSoSOptions_PVi_Source_Reject_Reasons();

   const { property } = useQPVi_Property().data || {};

   const { data: qGetPreRejectProperties, isLoading: qGetPreRejectPropertiesLoading } = useQPVi_GetPreRejectProperties({
      pid: property?.pid || "",
      zipcode: property?.zip || "",
   });

   if (!property) return;

   const { status, address_one_line, zip } = property;

   const zipCodeMatches: string[] = address_one_line.match(/\d{5}(?!.*\d{5})/) || [];

   const isNewStatus = status === ESoS_PropertyStatus.NEW;

   const handleReasonChange = (reasonId: string) => {
      if (selectedReasons.includes(reasonId)) {
         setSelectedReasons(selectedReasons.filter((id) => id !== reasonId));
      } else {
         setSelectedReasons([...selectedReasons, reasonId]);
      }
   };

   const handleRejectProperty = (
      { blacklist_zipcode }: { blacklist_zipcode: boolean | undefined } = { blacklist_zipcode: undefined }
   ) => {
      mutateUpdateProperty({
         status: "Rejected",
         reasons: selectedReasons,
         rejectDetail,
         getNextQuickPick: isNewStatus,
         ...(blacklist_zipcode && { blacklist_zipcode }),
      }).then(() => toggle());
   };

   return (
      <article className={cx("contentC")}>
         <div className={cx("topC")}>
            <div className={cx("topLeft")}>
               <div className={cx("img-icon")}>
                  <img
                     className={cx({})}
                     src={CF_CDN_URL("/assets/property_validator/rate_review_black.svg ")}
                     alt=""
                  />
               </div>
               <div className={cx("headerC")}>
                  <span>Please provide a reason for rejection</span>
                  <p>You can select more than one answer</p>
               </div>
            </div>
            <div className={cx("close-btn")}>
               <button type="button" title="close" onClick={toggle}>
                  <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
               </button>
            </div>
         </div>

         {qPViSourceRejectReasons.isFetching && (
            <article className={`${styles.loading}`}>
               <SHrSpinner />
               <span>Loading Source Reject Reasons...</span>
            </article>
         )}

         <div className={cx("reasonsC")}>
            {qPViSourceRejectReasons.data?.options.map((reason, i) => (
               <SoSCheckbox
                  title={reason.label}
                  value={selectedReasons.includes(reason.value)}
                  onChange={() => handleReasonChange(reason.value)}
                  key={i}
               />
            ))}

            <div className={cx("otherReasonC")}>
               <span>
                  <b>Further Detail</b>
               </span>
               <textarea
                  placeholder="Share your view here..."
                  value={rejectDetail}
                  onChange={(e) => setRejectDetail(e.target.value)}
               />
            </div>
         </div>

         <SHrSosButton
            loading={!!isMutating}
            buttonProps={{ className: cx("btn", "reject"), onClick: () => handleRejectProperty() }}
         >
            Reject Lead
         </SHrSosButton>

         {zipCodeMatches.length === 0 ? (
            <SoSConfirmWrapper
               title="Pre reject properties count"
               // content={`
               //       Reject ${address_one_line}
               //       Blacklist ${zip}
               //       ${
               //          qGetPreRejectProperties && qGetPreRejectProperties.count > 0
               //             ? `Soft-reject ${qGetPreRejectProperties.count} NEW properties in ${zip}`
               //             : "No soft-reject properties"
               //       }
               //    `}
               // content={`
               //       Reject ${address_one_line}
               //       Blacklist ${zip}
               //       ${
               //          qGetPreRejectProperties &&
               //          qGetPreRejectProperties.count > 0 &&
               //          `Soft-reject ${qGetPreRejectProperties.count} NEW properties in ${zip}`
               //       }
               //    `}
               content={
                  <div className={cx("wrapperC")}>
                     <ul>
                        <li>Reject: {address_one_line}</li>
                        <li>
                           Blacklist: <span>{zip}</span>
                        </li>
                        {qGetPreRejectProperties && qGetPreRejectProperties.count > 0 ? (
                           <li>
                              Soft-reject:{" "}
                              {qGetPreRejectPropertiesLoading ? (
                                 <SHrSpinner />
                              ) : (
                                 <span>{qGetPreRejectProperties?.count}</span>
                              )}{" "}
                              NEW properties in <span>{zip}</span>
                           </li>
                        ) : (
                           <li>No soft-reject properties</li>
                        )}
                     </ul>
                  </div>
               }
               type="default"
               onConfirm={() => handleRejectProperty({ blacklist_zipcode: true })}
            >
               <SHrSosButton loading={!!isMutating} type="dangerous" buttonProps={{ className: cx("btn") }}>
                  Reject & Blacklist [Zip code: {property.zip}]
               </SHrSosButton>
            </SoSConfirmWrapper>
         ) : (
            zipCodeMatches.length > 0 &&
            zipCodeMatches.includes(zip) && (
               <SoSConfirmWrapper
                  title="Pre reject properties count"
                  // content={`
                  //       Reject ${address_one_line}
                  //       Blacklist ${zip}
                  //       ${
                  //          qGetPreRejectProperties && qGetPreRejectProperties.count > 0
                  //             ? `Soft-reject ${qGetPreRejectProperties.count} NEW properties in ${zip}`
                  //             : "No soft-reject properties"
                  //       }
                  //    `}
                  // content={`
                  //       Reject ${address_one_line}
                  //       Blacklist ${zip}
                  //       ${
                  //          qGetPreRejectProperties &&
                  //          qGetPreRejectProperties.count > 0 &&
                  //          `Soft-reject ${qGetPreRejectProperties.count} NEW properties in ${zip}`
                  //       }
                  //    `}
                  content={
                     <div className={cx("wrapperC")}>
                        <ul>
                           <li>Reject: {address_one_line}</li>
                           <li>
                              Blacklist: <span>{zip}</span>
                           </li>
                           {qGetPreRejectProperties && qGetPreRejectProperties.count > 0 ? (
                              <li>
                                 Soft-reject:{" "}
                                 {qGetPreRejectPropertiesLoading ? (
                                    <SHrSpinner />
                                 ) : (
                                    <span>{qGetPreRejectProperties?.count}</span>
                                 )}{" "}
                                 NEW properties in <span>{zip}</span>
                              </li>
                           ) : (
                              <li>No soft-reject properties</li>
                           )}
                        </ul>
                     </div>
                  }
                  type="default"
                  onConfirm={() => handleRejectProperty({ blacklist_zipcode: true })}
               >
                  <SHrSosButton loading={!!isMutating} type="dangerous" buttonProps={{ className: cx("btn") }}>
                     Reject & Blacklist [Zip code: {property.zip}]
                  </SHrSosButton>
               </SoSConfirmWrapper>
            )
         )}
      </article>
   );
};

type TRejectionProps = {
   toggle: () => void;
};
