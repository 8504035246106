import classNames from "classnames/bind";
import styles from "./TransactionInsuranceTab.module.scss";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { TransactionPropertySwitch } from "../TransactionPropertySwitch/TransactionPropertySwitch";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
import { SHrSosButton, SHrSpinner, SHrPopup } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import { SoSUploaderContainer } from "../../../components/common/SoSUploaderContainer/SoSUploaderContainer";
import { formatDate } from "../TransactionInspectionTab/TransactionInspectionTab";
import { useMTrans_DeleteInsurancePolicy } from "../../../hooks/transactions/mutates/insurancePolicy/useMTrans_DeleteInsurancePolicy";
import { TransactionAddNewInsurancePolicyModal } from "../TransactionAddNewInsurancePolicyModal/TransactionAddNewInsurancePolicyModal";
import { useState } from "react";

const cx = classNames.bind(styles);

export const TransactionInsuranceTab = () => {
   const { transaction, property } = useSelectedTransactionAndProperty();

   const { mutate: mutateDeleteInsPolicy, isPending: isPendingDeleteInsPolicy } = useMTrans_DeleteInsurancePolicy();

   const [isShowAddInsurancePolicyModal, setIsShowAddInsurancePolicyModal] = useState(false);

   if (!transaction) return null;

   const { properties } = transaction;

   const { insurance } = property || {};

   return (
      <div className={cx("container")}>
         <TransactionPropertySwitch properties={properties} />
         <div className={cx("itemC")}>
            <div className={cx("itemDetail", "isBackground")}>
               <SoSDataPoint type={"bold-value"} title="Monthly Cost" value={500000} format={"currency"} isMocked />
            </div>
            <div className={cx("itemDetail")}>
               <SoSDataPoint
                  type={"bold-title"}
                  title="Insurance Status"
                  value={"Qouted"}
                  // options={qInsuranceStatusOptions}
                  // backGroundColor={qInsuranceStatusOptions?.find((o) => o.value === insurance?.status)?.color}
               />
            </div>
            <div className={cx("itemDetail")}>
               <SoSDataPoint
                  type={"bold-title"}
                  title="Insurance Provider"
                  value={insurance?.insurance_provider?.name}
               />
               <SoSDataPoint type={"only-value"} value={insurance?.insurance_provider?.website} />
            </div>
         </div>
         {!insurance?.insurance_policies?.length ? (
            <div className={cx("noInfo")}>No insurance policies found</div>
         ) : (
            insurance?.insurance_policies?.map((policy, i) => (
               <div className={cx("insurance")} key={i}>
                  <div className={cx("insuranceStatus")}>
                     <SoSDataPoint type={"bold-title"} title="Insurance Policy Number" value={policy.number} />
                     <SoSDataPoint
                        type={"bold-title"}
                        title="Choose a date"
                        value={formatDate(policy.effective_date || "")}
                     />
                     <SoSDataPoint type={"bold-title"} title="Cost" value={policy.cost} />
                  </div>

                  <div className={cx("documentC")}>
                     <div className={cx("headingC")}>
                        <span>
                           <b>Insurance Policy (documents)</b>
                        </span>
                     </div>

                     <SoSUploaderContainer type="files" values={[]} onUpload={(files) => {}} onRemove={(file) => {}}>
                        <SHrSosButton type="text" buttonProps={{ className: cx("uploadFileBtn") }} loading={false}>
                           Upload a file
                        </SHrSosButton>
                     </SoSUploaderContainer>
                  </div>

                  {isPendingDeleteInsPolicy ? (
                     <SHrSpinner />
                  ) : (
                     <div className={cx("img-icon")} onClick={() => mutateDeleteInsPolicy({ ipoid: policy.ipoid })}>
                        <img src={CF_CDN_URL("/assets/clear_black.svg")} alt="" />
                     </div>
                  )}
               </div>
            ))
         )}
         <div className={cx("btnAdd")}>
            <SHrSosButton
               type="filled"
               buttonProps={{
                  onClick: () => setIsShowAddInsurancePolicyModal(true),
               }}
            >
               Add Policy
            </SHrSosButton>
         </div>
         <SHrPopup show={isShowAddInsurancePolicyModal}>
            <TransactionAddNewInsurancePolicyModal onClose={() => setIsShowAddInsurancePolicyModal(false)} />
         </SHrPopup>
      </div>
   );
};
