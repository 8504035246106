import classNames from "classnames/bind";
import styles from "./TransactionOverviewTab.module.scss";
import { SoSDataPoint } from "../../../components/SoSDataPoint/SoSDataPoint";
import { SHrSosButton } from "@simplyhomes/react";
import { CF_CDN_URL } from "../../../utils/CF_CDN_URL";
import DataTable, { TableColumn } from "react-data-table-component";
import { useTransactionOptions } from "../../../hooks/transactions/hooks/useTransactionOptions";
import { useSelectedTransactionAndProperty } from "../../../hooks/transactions/hooks/useSelectedTransactionAndProperty";
import { useSoSOptions_Offers_Simply_Terms } from "../../../hooks/queries/options/useQSoS_Options";
import { Transactions_GetTransactionById_Transaction_Contact } from "../../../hooks/transactions/queries/useQTrans_Transaction";
import { database_helpers_createPayloadHelper as dbcb } from "../../../hooks/database/mutations/utils";
import { useQSOS_Contacts } from "../../../hooks/contact/queries/useQSOS_Contacts";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { formatDate } from "../TransactionInspectionTab/TransactionInspectionTab";

const cx = classNames.bind(styles);

export type TContactInfo = {
   contact: Transactions_GetTransactionById_Transaction_Contact;
   object: string;
   relationship: string;
};

export const uniqueContacts = (contacts: TContactInfo[]): TContactInfo[] => {
   const seen = new Set();
   return contacts.filter((contact) => {
      const duplicate = seen.has(contact.contact.cid);
      seen.add(contact.contact.cid);
      return !duplicate;
   });
};

export const TransactionOverviewTab = () => {
   const { transactionsState } = useTransactionsContext();

   const { oid } = transactionsState;

   const { transaction, qGetTransaction } = useSelectedTransactionAndProperty();

   const {
      qEMDStatusOptions,
      qInspectionStatusOptions,
      qTitleCompanyStatusOptions,
      qFinancingStatusOptions,
      qClosingStatusOptions,
   } = useTransactionOptions();

   const qOffersTermTemplates = useSoSOptions_Offers_Simply_Terms();

   const qGetContacts = useQSOS_Contacts();

   const termOptions = qOffersTermTemplates.data?.options;

   if (!transaction) return null;

   const { emd_status, closing_status, financing_status, title_status, terms, properties, expire_date } = transaction;

   const contactArray = properties.flatMap(
      (property) =>
         property?.contacts
            ?.sort((a, b) => a.cid - b.cid)
            .map((contact) => ({
               contact,
               object: "Property",
               relationship: property.address_one_line,
            })) ?? []
   );

   const allContactsColumn: TableColumn<TContactInfo>[] = [
      {
         name: "Contact Name",
         cell: (contactInfo) => (
            <SoSDataPoint
               type="only-value-table"
               value={contactInfo.contact.name}
               database={dbcb("sos_contacts", [{ column: "cid", id: contactInfo.contact.cid }], () => {
                  qGetTransaction.refetch();
                  qGetContacts.refetch();
               })("name")}
            />
         ),
      },
      {
         name: "Email",
         cell: (contactInfo) => (
            <SoSDataPoint
               type="only-value-table"
               value={contactInfo.contact.email}
               database={dbcb("sos_contacts", [{ column: "cid", id: contactInfo.contact.cid }], () => {
                  qGetTransaction.refetch();
                  qGetContacts.refetch();
               })("email")}
            />
         ),
         width: "250px",
      },
      {
         name: "Phone",
         cell: (contactInfo) => (
            <SoSDataPoint
               type="only-value-table"
               value={contactInfo.contact.phone_primary}
               database={dbcb("sos_contacts", [{ column: "cid", id: contactInfo.contact.cid }], () => {
                  qGetTransaction.refetch();
                  qGetContacts.refetch();
               })("phone_primary")}
            />
         ),
      },
      { name: "Object", selector: (contactInfo) => contactInfo.object },
      { name: "Relationship", selector: (contactInfo) => contactInfo.relationship },
   ];

   // const db_T = dbcb("sos_properties", [{ column: "pid", id: p?.pid || "" }], () => qPVi_Property.refetch());

   return (
      <div className={cx("overviewC")}>
         <div className={cx("statusC")}>
            <div className={cx("detailC")}>
               <div className={cx("detail")}>
                  <SoSDataPoint
                     type={"bold-title"}
                     title="EMD Status"
                     value={emd_status}
                     options={qEMDStatusOptions}
                     backGroundColor={qEMDStatusOptions?.find((v) => v.label === emd_status)?.color}
                  />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint
                     type={"bold-title"}
                     title="Inspection Status"
                     options={qInspectionStatusOptions}
                     value={[]}
                     options_isMulti
                  />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint type={"bold-title"} title="Underwriting Status" value={"Status"} isMocked />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint
                     type={"bold-title"}
                     title="Title Status"
                     value={title_status}
                     options={qTitleCompanyStatusOptions}
                     backGroundColor={qTitleCompanyStatusOptions?.find((v) => v.label === title_status)?.color}
                  />
               </div>
            </div>

            <div className={cx("detailC")}>
               <div className={cx("detail")}>
                  <SoSDataPoint
                     type={"bold-title"}
                     title="Financing Status"
                     value={financing_status}
                     options={qFinancingStatusOptions}
                     backGroundColor={qFinancingStatusOptions?.find((v) => v.label === financing_status)?.color}
                  />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint type={"bold-title"} title="Walkthrough Status" value={"Status"} isMocked />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint
                     type={"bold-title"}
                     title="Closing Status"
                     value={closing_status}
                     options={qClosingStatusOptions}
                     backGroundColor={qClosingStatusOptions?.find((v) => v.label === closing_status)?.color}
                  />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint
                     type={"bold-title"}
                     title="Estimated Closing Date"
                     value={formatDate(expire_date || "")}
                  />
               </div>
            </div>
            <div className={cx("detailC")}>
               <div className={cx("detail")}>
                  <SoSDataPoint type={"bold-title"} title="Team Lead" value={"Adam"} isMocked />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint type={"bold-title"} title="Coordinator" value={"Adam"} isMocked />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint type={"bold-title"} title="Properties" value={[]} isMocked options={[]} />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint type={"bold-title"} title="PSA" value={[]} isMocked options={[]} />
               </div>
            </div>
         </div>
         <hr className={cx("divide")} />

         <div className={cx("contractC")}>
            <span className={cx("contractTitle")}>Contract Details</span>
            <div className={cx("detailC")}>
               <div className={cx("detail")}>
                  <SoSDataPoint type={"bold-title"} title="Contract Signed Date" value={"12/15/2024"} isMocked />
               </div>
               <div className={cx("detail")}>
                  <SoSDataPoint
                     type={"bold-title"}
                     title="Contract Expiration Date"
                     value={formatDate(expire_date || "")}
                  />
               </div>
            </div>
            <div>
               <span className={cx("termsHeading")}>Default Terms</span>
               <div className={cx("termsC")}>
                  {!terms.simply?.length ? (
                     <div className={cx("noInfo")}>No simply terms found</div>
                  ) : (
                     terms?.simply?.map((term, i) => (
                        <div className={cx("terms", { isGreyBackground: term.title === "Purchase Price" })} key={i}>
                           <SoSDataPoint
                              type={term.title === "Purchase Price" ? "bold-value" : "bold-title"}
                              title={termOptions?.find((o) => o.value === term.title)?.label}
                              value={term.default}
                           />
                        </div>
                     ))
                  )}
               </div>
            </div>
            <div className={cx("customTermsC")}>
               <div className={cx("additionalC")}>
                  {terms.additional.length ? (
                     <div className={cx("noInfo")}>No additional terms found</div>
                  ) : (
                     terms?.additional?.map((term, i) => <span key={i}>{term}</span>)
                  )}
               </div>
            </div>
         </div>
         <hr className={cx("divide")} />

         <div className={cx("buyerC")}>
            <span className={cx("buyerTitle")}>Buyer Details</span>
            <div className={cx("buyerDetailC")}>
               <div className={cx("buyerDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Buyer (Owner Entity)" value={"Full Name"} isMocked />
                  <SoSDataPoint type={"bold-title"} value={0} isMocked />
               </div>
               <div className={cx("buyerDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Entity" value={"Owner Entity"} isMocked />
                  <SoSDataPoint type={"bold-title"} value={"Sub class"} isMocked />
               </div>
               <div className={cx("buyerDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Owner Address" value={"Address"} isMocked />
               </div>
               <div className={cx("buyerDetail")}>
                  <SoSDataPoint type={"bold-title"} title="Owner Entity POC" value={"Full Name"} isMocked />
                  <SoSDataPoint type={"bold-title"} value={0} isMocked />
               </div>
            </div>
         </div>
         <hr className={cx("divide")} />

         <div className={cx("agentC")}>
            <span className={cx("agentTitle")}>Agent Details</span>
            <div className={cx("agent")}>
               <div className={cx("agentDetailC")}>
                  <div className={cx("agentDetail", "isOnly")}>
                     <SoSDataPoint
                        type={"bold-value"}
                        title="Listing Agent Commision"
                        value={500}
                        isMocked
                        format="currency"
                     />
                  </div>
               </div>

               <div className={cx("agentDetailC")}>
                  <div className={cx("agentDetail")}>
                     <SoSDataPoint type={"bold-title"} title="Listing Agent " value={"Full Name"} isMocked />
                     <SoSDataPoint type={"bold-title"} value={0} isMocked />
                  </div>
                  <div className={cx("agentDetail")}>
                     <SoSDataPoint type={"bold-title"} title="Listing Agent POC" value={"Owner Entity"} isMocked />
                     <SoSDataPoint type={"bold-title"} value={0} isMocked />
                  </div>
                  <div className={cx("agentDetail")}>
                     <SoSDataPoint type={"bold-title"} title="Listing Agent Brokerage" value={"Brokerage"} isMocked />
                  </div>
                  <div className={cx("agentDetail")}>
                     <SoSDataPoint type={"bold-title"} title="Listing Agent Broker ID" value={"ID"} isMocked />
                     <SoSDataPoint type={"bold-title"} value={0} isMocked />
                  </div>
               </div>
               <div className={cx("agentDetailC")}>
                  <div className={cx("agentDetail", "isOnly")}>
                     <SoSDataPoint
                        type={"bold-value"}
                        title="Simply Agent Commision"
                        value={500}
                        format={"currency"}
                        isMocked
                     />
                  </div>
               </div>
               <div className={cx("agentDetailC")}>
                  <div className={cx("agentDetail")}>
                     <SoSDataPoint type={"bold-title"} title="Simply Agent" value={"Full Name"} isMocked />
                     <SoSDataPoint type={"bold-title"} value={0} isMocked />
                  </div>
                  <div className={cx("agentDetail")}>
                     <SoSDataPoint type={"bold-title"} title="Simply Agent POC" value={"Full Name"} isMocked />
                     <SoSDataPoint type={"bold-title"} value={0} isMocked />
                  </div>
                  <div className={cx("agentDetail")}>
                     <SoSDataPoint type={"bold-title"} title="Simply Agent Brokerage" value={"Brokerage"} isMocked />
                  </div>
                  <div className={cx("agentDetail")}>
                     <SoSDataPoint type={"bold-title"} title="Simply Agent Broker ID" value={"ID"} isMocked />
                  </div>
               </div>
            </div>
         </div>
         <div className={cx("container")}>
            <div className={cx("addPropertyC")}>
               <span>Related Contacts</span>
               <SHrSosButton
                  type="text"
                  buttonProps={{
                     className: cx("btn"),
                     onClick: () => {},
                  }}
               >
                  <div className={cx("img-icon")}>
                     <img src={CF_CDN_URL("/assets/offer/refresh_info500.svg")} alt="" />
                  </div>
                  Refresh
               </SHrSosButton>
            </div>

            <div className={cx("tableContainer")}>
               <DataTable
                  columns={allContactsColumn}
                  data={uniqueContacts(contactArray)}
                  pagination
                  customStyles={{
                     headRow: {
                        style: {
                           backgroundColor: "#E2EBF3",
                        },
                     },
                  }}
               />
            </div>
         </div>
      </div>
   );
};
