import { useQuery } from "@tanstack/react-query";
import { useTransactionsContext } from "../../../contexts/TransactionsContext";
import { useSosAxios } from "../../axios/useSosAxios";
import { Transactions_GetTransactions_Transaction_Inspection } from "./useQTrans_Transactions";
import { TPgSoS_SoSUtilitiesAllowances_Schema_Data } from "@simplyhomes/utils/dist/types/pg/sos/utilities-allowances";

export const useQTrans_Transaction = () => {
   const { sosAxios } = useSosAxios();

   const { oid } = useTransactionsContext().transactionsState;

   return useQuery({
      queryKey: [`useQTrans_Transaction`, oid],
      queryFn: () =>
         sosAxios
            .get(`/transactions/${oid}`)
            .then<Transactions_GetTransactionById_Response>(
               ({ data }: { data: Transactions_GetTransactionById_Response }) => data
            ),
   });
};

export class Transactions_GetTransactionById_Transaction_Insurance_Provider {
   ipid!: number;
   name!: string;
   code!: string;
   website?: string;
}

export class Transactions_GetTransactionById_Transaction_Insurance_Policy {
   ipoid!: number;
   number!: string;
   effective_date?: string;
   documents!: number[];
   cost!: number;
   status!: string;
}

export class Transactions_GetTransactionById_Transaction_Insurance {
   insurance_provider!: Transactions_GetTransactionById_Transaction_Insurance_Provider;
   insurance_policies!: Transactions_GetTransactionById_Transaction_Insurance_Policy[];
}

export class Transactions_GetTransactionById_Transaction_Inspection extends Transactions_GetTransactions_Transaction_Inspection {
   inspection_date?: string;
   inspection_cost?: number;
   reno_person?: string;
   assigned_poc?: string;
   current_occupancy?: string;
   access_details?: string;
   notes?: string;
}

export class Transactions_GetTransactionById_Transaction_Walkthough {
   wid!: number;
   pid!: string;
   status?: string;
   walkthrough_date?: string;
   reno_person?: string;
   assigned_poc?: string;
   notes?: string;
}

export class Transactions_GetTransactionById_Transaction_Property_Unit_Utilities_Config {
   groupTitle!: string;
   lineTitle!: string;
}

export class Transactions_GetTransactionById_Transaction_Property_Unit {
   uid!: number;
   beds!: number;
   baths!: number;
   leasing_type?: string;
   leasing_rent?: number;
   leasing_end?: string;
   utilities_config!: Transactions_GetTransactionById_Transaction_Property_Unit_Utilities_Config[];
}

class Transactions_GetTransactionById_Transaction_Property_Scope_Of_Work {
   fuid!: number;
   file_name!: string;
}

export class Transactions_GetTransactionById_Transaction_Property {
   pid!: string;
   address_one_line!: string;
   street_view_url_150!: string;
   units!: Transactions_GetTransactionById_Transaction_Property_Unit[];
   inspection?: Transactions_GetTransactionById_Transaction_Inspection;
   insurance?: Transactions_GetTransactionById_Transaction_Insurance;
   walkthrough?: Transactions_GetTransactionById_Transaction_Walkthough;
   contacts?: Transactions_GetTransactionById_Transaction_Contact[];
   source!: Transactions_GetTransactionById_Transaction_Source;
   utilities_allowances!: Transactions_GetTransactionById_Transaction_Property_Utilities_Allowances;
   neighborhood_name!: string;
   market_name!: string;
   sqft!: number;
   zip!: string;
   year_built!: number;
   property_manager_id!: number;
   property_manager_name!: string;
   housing_authority_name!: string;
   scope_of_works?: Transactions_GetTransactionById_Transaction_Property_Scope_Of_Work[];
   stories!: number;
}

type Transactions_GetTransactionById_Transaction_Property_Utilities_Allowances =
   TPgSoS_SoSUtilitiesAllowances_Schema_Data;

export class Transactions_GetTransactionById_Transaction_Source {
   sid!: number;
   source_source!: string;
   created_at!: string;
   contacts?: Transactions_GetTransactionById_Transaction_Contact[];
}

export type SoS_Offers_UpdateTermTemplate_Data_Simply_Field = {
   title: string;
   default?: string | number;
};

export type Transactions_GetTransactionById_Transaction_Terms = {
   additional: string[];
   simply?: SoS_Offers_UpdateTermTemplate_Data_Simply_Field[];
};

export type Transactions_GetTransactionById_Transaction_Contact = {
   cid: number;
   name: string;
   phone_primary: string;
   email: string;
};

export class Transactions_GetTransactionById_Transaction {
   oid!: number;
   lid!: number;
   name!: string;
   expire_date?: string;
   emd_status?: string;
   title_status?: string;
   financing_status?: string;
   closing_status?: string;
   emd_start_date?: string;
   emd_end_date?: string;
   title_start_date?: string;
   title_end_date?: string;
   financing_start_date?: string;
   financing_end_date?: string;
   closing_start_date?: string;
   closing_end_date?: string;
   market_name?: string;
   terms!: Transactions_GetTransactionById_Transaction_Terms;
   properties!: Transactions_GetTransactionById_Transaction_Property[];
   emd_amount?: number;
}

type Transactions_GetTransactionById_Response = {
   transaction: Transactions_GetTransactionById_Transaction;
};
