import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const useQPVi_GetPreRejectProperties = ({ pid, zipcode }: TUseQPVi_GetPreRejectProperties) => {
   const { sosAxios } = useSosAxios();

   return useQuery({
      enabled: !!zipcode,
      queryKey: [`useQPVi_PreRejectProperties`, pid, zipcode],
      refetchOnMount: true,
      queryFn: () =>
         sosAxios
            .get(`/property-viability/properties/pre-reject`, { params: { pid, zipcode } })
            .then<TPVi_GetPreRejectProperties_Response>(({ data }) => data),
   });
};

type TUseQPVi_GetPreRejectProperties = {
   pid?: string;
   zipcode: string;
};

export class TPVi_GetPreRejectProperties_Response {
   count!: number;
}
