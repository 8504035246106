import { useIsMutating, useMutation } from "@tanstack/react-query";
import { useToastContext } from "../../../contexts/ToastContext";
import { useSosAxios } from "../../axios/useSosAxios";
import { Database_Column, Database_Table } from "./utils";

export const useMDatabase_RemoveFile = (p?: useMDatabase_RemoveFile_Params) => {
   const { table, column, identifiers, onComplete = () => {} } = p || {};
   const { sosAxios } = useSosAxios({ redirectOnError: false });
   const { toastDispatch } = useToastContext();
   const mutationKey = [table, column, identifiers];

   const mutation = useMutation({
      mutationKey,
      onSuccess: () => {
         onComplete(true);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "success", message: "Removed File" } });
      },
      onError: () => {
         onComplete(false);
         toastDispatch({ type: "ADD_TOAST", payload: { type: "error", message: "Remove failed" } });
      },
      mutationFn: ({ fuid }: { fuid: number }) =>
         sosAxios
            .delete(`/database/files/${fuid}`, {
               data: { table, column, identifiers },
            })
            .then(({ data }) => data),
   });
   const isMutating = useIsMutating({ mutationKey });

   return { ...mutation, isMutating };
};

export type useMDatabase_RemoveFile_Params = {
   table: Database_Table;
   column: Database_Column;
   identifiers: { column: string; id: string | number }[];
   onComplete?: (isSuccess: boolean) => void;
};
