import { useQuery } from "@tanstack/react-query";
import { useSosAxios } from "../../axios/useSosAxios";

export const usePVi_ZipCodes = () => {
   const { sosAxios } = useSosAxios();

   const query = useQuery({
      queryKey: ["usePVi_ZipCodes"],
      queryFn: () =>
         sosAxios
            .get("/property-viability/properties/zipcodes")
            .then(({ data }: { data: PVi_GetZipcodes_Response }) => {
               const zipcodesMap = data.zipcodes.reduce(
                  (acc, zip) => ({ ...acc, [zip.zid]: zip }),
                  {} as { [key: string]: PVi_GetZipcodes_Zipcode }
               );

               // const slicedData = data.zipcodes.slice(0, 20);

               return { ...data, zipcodesMap };
            }),
   });

   return query;
};

export class PVi_GetZipcodes_Zipcode {
   zid!: string;
   blacklisted!: boolean;
   x!: number;
   y!: number;
   city?: string;
   state?: string;
}

export class PVi_GetZipcodes_Filter {
   blacklisted_count!: number;
   all!: number;
}

export class PVi_GetZipcodes_Response {
   zipcodes!: PVi_GetZipcodes_Zipcode[];
   filter!: PVi_GetZipcodes_Filter;
}
