import classNames from "classnames/bind";
import styles from "./OffersLeadCard.module.scss";
import { useOffersContext } from "../../../contexts/OffersContext";
import { useQOffers_GetLeads } from "../../../hooks/offers/queries/leads/useQOffers_GetLeads";
import {
   SoS_GetOptions_Option,
   useSoSOptions_Offers_Lead_Next_Actions,
   useSoSOptions_Offers_OfferStatuses,
} from "../../../hooks/queries/options/useQSoS_Options";
import { useSoSNavigate } from "../../../hooks/navigate/useSoSNavigate";
const cx = classNames.bind(styles);

export const formatDate = (dateString: string): string => {
   if (!dateString) return "";

   const date = new Date(dateString);

   const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      day: "numeric",
      month: "short",
      year: "numeric",
   };

   return new Intl.DateTimeFormat("en-US", options).format(date);
};

const mapLeadNextActions = (actionValues: string[], leadNextActionOptions: SoS_GetOptions_Option[]): string[] => {
   const actionMap = leadNextActionOptions?.reduce((acc, action) => {
      acc[action.value] = action.label;
      return acc;
   }, {} as Record<string, string>);

   return actionValues.map((value) => actionMap?.[value] || "Unknown Action");
};

export const OffersLeadCard = ({ lid }: TOffersLeadCardProps) => {
   const { nav } = useSoSNavigate();

   const { offersState, offersOverwrite } = useOffersContext();

   const { lid: lidContext } = offersState;

   const qOffersLeadNextActions = useSoSOptions_Offers_Lead_Next_Actions();

   const qOpOffers = useSoSOptions_Offers_OfferStatuses().data?.options || [];

   const { options: leadNextActionOptions } = qOffersLeadNextActions.data! || {};

   const qOfferLeads = useQOffers_GetLeads();
   if (!qOfferLeads.data) return null;

   const { leadsMap } = qOfferLeads.data;
   const lead = leadsMap[lid];

   if (!lead) return null;
   const { offer_status, name, created_at, properties_count } = lead;

   const handleSelectedLead = () => {
      nav(lidContext === lid ? 0 : lid);
      offersOverwrite({ pid: "" });
   };

   const actionLabels = mapLeadNextActions(lead.next_actions, leadNextActionOptions);
   const offerOption = qOpOffers.find((o) => o.value === offer_status);
   return (
      <div className={cx("container", { selected: lidContext === lid })} onClick={() => handleSelectedLead()}>
         <div className={cx("nameC")}>
            <span className={cx("title")}>{name}</span>
         </div>
         <div className={cx("propertiesCountC")}>
            <span>Properties in Lead</span>
            <span>
               <b>{properties_count}</b>
            </span>
         </div>
         <div className={cx("statusesC")}>
            <div className={cx("statusC")}>
               <span>Latest Offer</span>
               <div className={cx("title")} style={{ backgroundColor: offerOption?.color }}>
                  <span>{offerOption?.label || "-"}</span>
               </div>
            </div>
            <div className={cx("statusC")}>
               <span>Lead Next Actions</span>
               <div className={cx("title")}>
                  {actionLabels.map((line, index) => (
                     <span key={index}>
                        {line},
                        <br />
                     </span>
                  ))}
               </div>
            </div>
         </div>
         <div className={cx("timeC")}>
            <span>Created at</span>
            <span>
               <b>{formatDate(created_at)}</b>
            </span>
         </div>
         <div className={cx("timeC")}>
            <span>Assignee</span>
            <span>
               <b>{lead.assignee.join(", ")}</b>
            </span>
         </div>
      </div>
   );
};

type TOffersLeadCardProps = {
   lid: number;
};
