import { SHrSosButton } from "@simplyhomes/react";
import SoSSlider, { SoSSlider_Item } from "../../../../components/SoSSlider/SoSSlider";
import { CF_CDN_URL } from "../../../../utils/CF_CDN_URL";
import classNames from "classnames/bind";
import styles from "./PViMediaSlider.module.scss";
import { useQPVi_Property } from "../../../../hooks/property-viability/queries/useQPVi_Property";
import { useFEMA_FloodMap } from "../../../../hooks/FEMA/useFEMA_FloodMap";

const cx = classNames.bind(styles);

export const PViMediaSlider = () => {
   const { property } = useQPVi_Property().data || {};

   const { street_view_url_400, lat, lng } = property || {};
   const { mapSrc, overlaySrc } = useFEMA_FloodMap({ lat, lng });
   const items: SoSSlider_Item[] = [];
   if (lat && lng) {
      items.push({ type: "map", coordinate: { lat, lng } });
      items.push({ type: "image", src: mapSrc, overlaySrc });
   }
   if (street_view_url_400) items.push({ type: "image", src: street_view_url_400 });
   return (
      <div className={cx("mediaC", "_jr_pviMediaSlider")}>
         <SoSSlider items={items}></SoSSlider>
         <div className={cx("addPhotosC")}>
            <SHrSosButton buttonProps={{ className: cx("btnAdd") }} type="outlined">
               <div className={cx("img-icon")}>
                  <img src={CF_CDN_URL("/assets/plus_white.svg")} alt="" />
               </div>
               <span>Add Photos</span>
            </SHrSosButton>
         </div>
      </div>
   );
};
