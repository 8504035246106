import classNames from "classnames/bind";
import styles from "./DevTimmy.module.scss";
import { TestTable } from "./TestTable/TestTable";
import { TestUploader } from "./TestUploader/TestUploader";

const cx = classNames.bind(styles);

export const DevTimmy = () => {
   return (
      <div className={cx("container")}>
         {/* <TestTable /> */}
         <TestUploader />
      </div>
   );
};
